/*
? First, let's import the global state interface.
*/

import StateInterface from "../state-interface";

/*
& Now let's describe an initial state for the faq object. This is the state that will be used when the app first fires up. Think of it like a placeholder.
*/

const faq_data: StateInterface["faq"] = {
  _id: "62b2e32e99a2279a2dd33960",
  ref: 1,
  slug: "how-do-i-place-my-bid",
  category: "bidding",
  question: "How do I place my bid?",
  answer:
    "You can place your bid by clicking on the bid button on the product page.",
  more: [],
  upvotes: [],
  downvotes: [],
  created_at: "2020-11-01T12:00:00.000Z",
  updated_at: "2020-11-01T12:00:00.000Z",
};

export const faq = faq_data;
export default faq_data;
