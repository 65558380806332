import {
	SearchFilter,
	SearchFilterOptionText,
	SearchFilterOptionTextActive,
} from "../../../redux-magic/sub-interfaces/search-types";

export const _t_furnishing: SearchFilter = {
	id: "furnishing",
	title: "Furnishing",
	level: 3,
	index: 19,
	presentation_type: "chips",
	multi: true,
	affects: [],
	affected_by: ["property_type"],
	priority: 3,
	is_advanced: true,
	included_in_search_bar: false,
	option_type: "text",
	possible_options: [
		{
			id: "furnished",
			title: "Furnished",
			value: "furnished",
		},
		{
			id: "semi-furnished",
			title: "Semi-furnished",
			value: "semi-furnished",
		},
		{
			id: "unfurnished",
			title: "Unfurnished",
			value: "unfurnished",
		},
	] as Array<SearchFilterOptionText>,
	active_options: [
		{
			id: "furnished",
			title: "Furnished",
			value: "furnished",
			is_selected: false,
		},
		{
			id: "semi-furnished",
			title: "Semi-furnished",
			value: "semi-furnished",
			is_selected: false,
		},
		{
			id: "unfurnished",
			title: "Unfurnished",
			value: "unfurnished",
			is_selected: false,
		},
	] as Array<SearchFilterOptionTextActive>,
	selected_options: [] as Array<SearchFilterOptionText>,
	db_collection: "properties",
	db_field: "furnished",
	is_visible: false,
	is_applied: false,
	is_accordion_open: false,
};
