import {
	SearchFilter,
	SearchFilterOptionText,
	SearchFilterOptionTextActive,
} from "../../../redux-magic/sub-interfaces/search-types";

/*

TODO: The data in this filter requires database match testing because it might not be accurate.

*/

export const _u_amenities: SearchFilter = {
	id: "amenities",
	title: "Amenities",
	level: 3,
	index: 20,
	presentation_type: "autocomplete_text_field",
	multi: true,
	affects: [],
	affected_by: ["property_type"],
	priority: 3,
	is_advanced: true,
	included_in_search_bar: false,
	option_type: "text",
	possible_options: [
		{
			id: "jogging-track",
			title: "Jogging Track",
			value: "jogging-track",
		},
		{
			id: "swimming-pool",
			title: "Swimming Pool",
			value: "swimming-pool",
		},
		{
			id: "heated-swimming-pool",
			title: "Heated Swimming Pool",
			value: "heated-swimming-pool",
		},
		{
			id: "infinity-pool",
			title: "Infinity Pool",
			value: "infinity-pool",
		},
		{
			id: "toddler-pool",
			title: "Toddler Pool",
			value: "toddler-pool",
		},
		{
			id: "clubhouse",
			title: "Clubhouse",
			value: "clubhouse",
		},
		{
			id: "bonfire-area",
			title: "Bonfire Area",
			value: "bonfire-area",
		},
		{
			id: "park",
			title: "Park",
			value: "park",
		},
		{
			id: "senior-citizen-park",
			title: "Senior Citizen Park",
			value: "senior-citizen-park",
		},
		{
			id: "rain-water-harvesting",
			title: "Rain Water Harvesting",
			value: "rain-water-harvesting",
		},
		{
			id: "security",
			title: "Security",
			value: "security",
		},
		{
			id: "gym",
			title: "Gym",
			value: "gym",
		},
		{
			id: "cctv-surveillance",
			title: "CCTV Surveillance",
			value: "cctv-surveillance",
		},
		{
			id: "amphitheatre",
			title: "Amphitheatre",
			value: "amphitheatre",
		},
		{
			id: "mini-theatre",
			title: "Mini Theatre",
			value: "mini-theatre",
		},
		{
			id: "yoga-deck",
			title: "Yoga Deck",
			value: "yoga-deck",
		},
		{
			id: "landscaped-garden",
			title: "Landscaped Garden",
			value: "landscaped-garden",
		},
		{
			id: "kids-play-area",
			title: "Kids Play Area",
			value: "kids-play-area",
		},
		{
			id: "supermarket",
			title: "Supermarket",
			value: "supermarket",
		},
		{
			id: "atm",
			title: "Atm",
			value: "atm",
		},
		{
			id: "pharmacy",
			title: "Pharmacy",
			value: "pharmacy",
		},
		{
			id: "tennis-court",
			title: "Tennis Court",
			value: "tennis-court",
		},
		{
			id: "basketball-court",
			title: "Basketball Court",
			value: "basketball-court",
		},
		{
			id: "badminton-court",
			title: "Badminton Court",
			value: "badminton-court",
		},
		{
			id: "table-tennis",
			title: "Table Tennis",
			value: "table-tennis",
		},
		{
			id: "indoor-games",
			title: "Indoor Games",
			value: "indoor-games",
		},
		{
			id: "billiards",
			title: "Billiards",
			value: "billiards",
		},
		{
			id: "skating-rink",
			title: "Skating Rink",
			value: "skating-rink",
		},
		{
			id: "volleyball-court",
			title: "Volleyball Court",
			value: "volleyball-court",
		},
		{
			id: "sand-pit",
			title: "Sand Pit",
			value: "sand-pit",
		},
		{
			id: "cricket-practice-net",
			title: "Cricket Practice Net",
			value: "cricket-practice-net",
		},
		{
			id: "football-turf",
			title: "Football Turf",
			value: "football-turf",
		},
		{
			id: "lift",
			title: "Lift",
			value: "lift",
		},
		{
			id: "cafeteria",
			title: "Cafeteria",
			value: "cafeteria",
		},
		{
			id: "car-parking",
			title: "Car Parking",
			value: "car-parking",
		},
		{
			id: "covered-car-parking",
			title: "Covered Car Parking",
			value: "covered-car-parking",
		},
		{
			id: "visitor-parking",
			title: "Visitor Parking",
			value: "visitor-parking",
		},
		{
			id: "electric-car-charging-station",
			title: "Electric Car Charging Station",
			value: "electric-car-charging-station",
		},
		{
			id: "library",
			title: "Library",
			value: "library",
		},
		{
			id: "reading-room",
			title: "Reading Room",
			value: "reading-room",
		},
		{
			id: "multipurpose-hall",
			title: "Multipurpose Hall",
			value: "multipurpose-hall",
		},
		{
			id: "jacuzzi",
			title: "Jacuzzi",
			value: "jacuzzi",
		},
		{
			id: "sauna",
			title: "Sauna",
			value: "sauna",
		},
		{
			id: "spa",
			title: "Spa",
			value: "spa",
		},
		{
			id: "barbecue-area",
			title: "Barbecue Area",
			value: "barbecue-area",
		},
		{
			id: "squash-court",
			title: "Squash Court",
			value: "squash-court",
		},
		{
			id: "power-backup",
			title: "Power Backup",
			value: "power-backup",
		},
		{
			id: "helipad",
			title: "Helipad",
			value: "helipad",
		},
		{
			id: "sewage-treatment-plant",
			title: "Sewage Treatment Plant",
			value: "sewage-treatment-plant",
		},
		{
			id: "intercom",
			title: "Intercom",
			value: "intercom",
		},
		{
			id: "water-softening-plant",
			title: "Water Softening Plant",
			value: "water-softening-plant",
		},
		{
			id: "fire-safety-system",
			title: "Fire Safety System",
			value: "fire-safety-system",
		},
		{
			id: "butterfly-garden",
			title: "Butterfly Garden",
			value: "butterfly-garden",
		},
		{
			id: "cycling-track",
			title: "Cycling Track",
			value: "cycling-track",
		},
		{
			id: "waiting-lounge",
			title: "Waiting Lounge",
			value: "waiting-lounge",
		},
		{
			id: "solar-lighting",
			title: "Solar Lighting",
			value: "solar-lighting",
		},
		{
			id: "salon",
			title: "Salon",
			value: "salon",
		},
		{
			id: "meditation-zone",
			title: "Meditation Zone",
			value: "meditation-zone",
		},
		{
			id: "maintenance-staff",
			title: "Maintenance Staff",
			value: "maintenance-staff",
		},
		{
			id: "outdoor-lounge",
			title: "Outdoor Lounge",
			value: "outdoor-lounge",
		},
		{
			id: "health-clinic",
			title: "Health Clinic",
			value: "health-clinic",
		},
		{
			id: "restaurant",
			title: "Restaurant",
			value: "restaurant",
		},
		{
			id: "golf-course",
			title: "Golf Course",
			value: "golf-course",
		},
		{
			id: "waste-management-plant",
			title: "Waste Management Plant",
			value: "waste-management-plant",
		},
		{
			id: "business-centre",
			title: "Business Centre",
			value: "business-centre",
		},
		{
			id: "party-counter",
			title: "Party Counter",
			value: "party-counter",
		},
		{
			id: "service-lift",
			title: "Service Lift",
			value: "service-lift",
		},
		{
			id: "gas-pipeline",
			title: "Gas Pipeline",
			value: "gas-pipeline",
		},
		{
			id: "play-school",
			title: "Play School",
			value: "play-school",
		},
		{
			id: "terrace-private-garden",
			title: "Terrace / Private Garden",
			value: "terrace-private-garden",
		},
	] as Array<SearchFilterOptionText>,
	active_options: [
		{
			id: "jogging-track",
			title: "Jogging Track",
			value: "jogging-track",
			is_selected: false,
		},
		{
			id: "swimming-pool",
			title: "Swimming Pool",
			value: "swimming-pool",
			is_selected: false,
		},
		{
			id: "heated-swimming-pool",
			title: "Heated Swimming Pool",
			value: "heated-swimming-pool",
			is_selected: false,
		},
		{
			id: "infinity-pool",
			title: "Infinity Pool",
			value: "infinity-pool",
			is_selected: false,
		},
		{
			id: "toddler-pool",
			title: "Toddler Pool",
			value: "toddler-pool",
			is_selected: false,
		},
		{
			id: "clubhouse",
			title: "Clubhouse",
			value: "clubhouse",
			is_selected: false,
		},
		{
			id: "bonfire-area",
			title: "Bonfire Area",
			value: "bonfire-area",
			is_selected: false,
		},
		{
			id: "park",
			title: "Park",
			value: "park",
			is_selected: false,
		},
		{
			id: "senior-citizen-park",
			title: "Senior Citizen Park",
			value: "senior-citizen-park",
			is_selected: false,
		},
		{
			id: "rain-water-harvesting",
			title: "Rain Water Harvesting",
			value: "rain-water-harvesting",
			is_selected: false,
		},
		{
			id: "security",
			title: "Security",
			value: "security",
			is_selected: false,
		},
		{
			id: "gym",
			title: "Gym",
			value: "gym",
			is_selected: false,
		},
		{
			id: "cctv-surveillance",
			title: "CCTV Surveillance",
			value: "cctv-surveillance",
			is_selected: false,
		},
		{
			id: "amphitheatre",
			title: "Amphitheatre",
			value: "amphitheatre",
			is_selected: false,
		},
		{
			id: "mini-theatre",
			title: "Mini Theatre",
			value: "mini-theatre",
			is_selected: false,
		},
		{
			id: "yoga-deck",
			title: "Yoga Deck",
			value: "yoga-deck",
			is_selected: false,
		},
		{
			id: "landscaped-garden",
			title: "Landscaped Garden",
			value: "landscaped-garden",
			is_selected: false,
		},
		{
			id: "kids-play-area",
			title: "Kids Play Area",
			value: "kids-play-area",
			is_selected: false,
		},
		{
			id: "supermarket",
			title: "Supermarket",
			value: "supermarket",
			is_selected: false,
		},
		{
			id: "atm",
			title: "Atm",
			value: "atm",
			is_selected: false,
		},
		{
			id: "pharmacy",
			title: "Pharmacy",
			value: "pharmacy",
			is_selected: false,
		},
		{
			id: "tennis-court",
			title: "Tennis Court",
			value: "tennis-court",
			is_selected: false,
		},
		{
			id: "basketball-court",
			title: "Basketball Court",
			value: "basketball-court",
			is_selected: false,
		},
		{
			id: "badminton-court",
			title: "Badminton Court",
			value: "badminton-court",
			is_selected: false,
		},
		{
			id: "table-tennis",
			title: "Table Tennis",
			value: "table-tennis",
			is_selected: false,
		},
		{
			id: "indoor-games",
			title: "Indoor Games",
			value: "indoor-games",
			is_selected: false,
		},
		{
			id: "billiards",
			title: "Billiards",
			value: "billiards",
			is_selected: false,
		},
		{
			id: "skating-rink",
			title: "Skating Rink",
			value: "skating-rink",
			is_selected: false,
		},
		{
			id: "volleyball-court",
			title: "Volleyball Court",
			value: "volleyball-court",
			is_selected: false,
		},
		{
			id: "sand-pit",
			title: "Sand Pit",
			value: "sand-pit",
			is_selected: false,
		},
		{
			id: "cricket-practice-net",
			title: "Cricket Practice Net",
			value: "cricket-practice-net",
			is_selected: false,
		},
		{
			id: "football-turf",
			title: "Football Turf",
			value: "football-turf",
			is_selected: false,
		},
		{
			id: "lift",
			title: "Lift",
			value: "lift",
			is_selected: false,
		},
		{
			id: "cafeteria",
			title: "Cafeteria",
			value: "cafeteria",
			is_selected: false,
		},
		{
			id: "car-parking",
			title: "Car Parking",
			value: "car-parking",
			is_selected: false,
		},
		{
			id: "covered-car-parking",
			title: "Covered Car Parking",
			value: "covered-car-parking",
			is_selected: false,
		},
		{
			id: "visitor-parking",
			title: "Visitor Parking",
			value: "visitor-parking",
			is_selected: false,
		},
		{
			id: "electric-car-charging-station",
			title: "Electric Car Charging Station",
			value: "electric-car-charging-station",
			is_selected: false,
		},
		{
			id: "library",
			title: "Library",
			value: "library",
			is_selected: false,
		},
		{
			id: "reading-room",
			title: "Reading Room",
			value: "reading-room",
			is_selected: false,
		},
		{
			id: "multipurpose-hall",
			title: "Multipurpose Hall",
			value: "multipurpose-hall",
			is_selected: false,
		},
		{
			id: "jacuzzi",
			title: "Jacuzzi",
			value: "jacuzzi",
			is_selected: false,
		},
		{
			id: "sauna",
			title: "Sauna",
			value: "sauna",
			is_selected: false,
		},
		{
			id: "spa",
			title: "Spa",
			value: "spa",
			is_selected: false,
		},
		{
			id: "barbecue-area",
			title: "Barbecue Area",
			value: "barbecue-area",
			is_selected: false,
		},
		{
			id: "squash-court",
			title: "Squash Court",
			value: "squash-court",
			is_selected: false,
		},
		{
			id: "power-backup",
			title: "Power Backup",
			value: "power-backup",
			is_selected: false,
		},
		{
			id: "helipad",
			title: "Helipad",
			value: "helipad",
			is_selected: false,
		},
		{
			id: "sewage-treatment-plant",
			title: "Sewage Treatment Plant",
			value: "sewage-treatment-plant",
			is_selected: false,
		},
		{
			id: "intercom",
			title: "Intercom",
			value: "intercom",
			is_selected: false,
		},
		{
			id: "water-softening-plant",
			title: "Water Softening Plant",
			value: "water-softening-plant",
			is_selected: false,
		},
		{
			id: "fire-safety-system",
			title: "Fire Safety System",
			value: "fire-safety-system",
			is_selected: false,
		},
		{
			id: "butterfly-garden",
			title: "Butterfly Garden",
			value: "butterfly-garden",
			is_selected: false,
		},
		{
			id: "cycling-track",
			title: "Cycling Track",
			value: "cycling-track",
			is_selected: false,
		},
		{
			id: "waiting-lounge",
			title: "Waiting Lounge",
			value: "waiting-lounge",
			is_selected: false,
		},
		{
			id: "solar-lighting",
			title: "Solar Lighting",
			value: "solar-lighting",
			is_selected: false,
		},
		{
			id: "salon",
			title: "Salon",
			value: "salon",
			is_selected: false,
		},
		{
			id: "meditation-zone",
			title: "Meditation Zone",
			value: "meditation-zone",
			is_selected: false,
		},
		{
			id: "maintenance-staff",
			title: "Maintenance Staff",
			value: "maintenance-staff",
			is_selected: false,
		},
		{
			id: "outdoor-lounge",
			title: "Outdoor Lounge",
			value: "outdoor-lounge",
			is_selected: false,
		},
		{
			id: "health-clinic",
			title: "Health Clinic",
			value: "health-clinic",
			is_selected: false,
		},
		{
			id: "restaurant",
			title: "Restaurant",
			value: "restaurant",
			is_selected: false,
		},
		{
			id: "golf-course",
			title: "Golf Course",
			value: "golf-course",
			is_selected: false,
		},
		{
			id: "waste-management-plant",
			title: "Waste Management Plant",
			value: "waste-management-plant",
			is_selected: false,
		},
		{
			id: "business-centre",
			title: "Business Centre",
			value: "business-centre",
			is_selected: false,
		},
		{
			id: "party-counter",
			title: "Party Counter",
			value: "party-counter",
			is_selected: false,
		},
		{
			id: "service-lift",
			title: "Service Lift",
			value: "service-lift",
			is_selected: false,
		},
		{
			id: "gas-pipeline",
			title: "Gas Pipeline",
			value: "gas-pipeline",
			is_selected: false,
		},
		{
			id: "play-school",
			title: "Play School",
			value: "play-school",
			is_selected: false,
		},
		{
			id: "terrace-private-garden",
			title: "Terrace / Private Garden",
			value: "terrace-private-garden",
			is_selected: false,
		},
	] as Array<SearchFilterOptionTextActive>,
	selected_options: [] as Array<SearchFilterOptionText>,
	db_collection: "properties",
	db_field: "amenities",
	is_visible: false,
	is_applied: false,
	is_accordion_open: false,
};
