import {
	SearchFilter,
	SearchFilterOptionText,
	SearchFilterOptionTextActive,
} from "../../../redux-magic/sub-interfaces/search-types";

export const _n_property_status: SearchFilter = {
	id: "property_status",
	title: "Property Status",
	level: 3,
	index: 13,
	presentation_type: "chips",
	multi: true,
	affects: ["possession_date", "available_from"],
	affected_by: ["property_type", "transaction_type"],
	priority: 3,
	is_advanced: true,
	included_in_search_bar: true,
	option_type: "text",
	possible_options: [
		{
			id: "ready_to_move_in",
			title: "Ready to Move In",
			value: "ready_to_move_in",
		},
		{
			id: "ready_for_registration",
			title: "Ready for Registration",
			value: "ready_for_registration",
		},
		{
			id: "under_construction",
			title: "Under Construction",
			value: "under_construction",
		},
		// {
		// 	id: "built_to_suit",
		// 	title: "Built to Suit",
		// 	value: "built_to_suit",
		// },
		// {
		// 	id: "bare_shell",
		// 	title: "Bare Shell",
		// 	value: "bare_shell",
		// },
		{
			id: "immediately",
			title: "Immediately",
			value: "immediately",
		},
		{
			id: "select_date",
			title: "Select Date",
			value: "select_date",
		},
	] as Array<SearchFilterOptionText>,
	active_options: [
		{
			id: "ready_to_move_in",
			title: "Ready to Move In",
			value: "ready_to_move_in",
			is_selected: false,
		},
		{
			id: "under_construction",
			title: "Under Construction",
			value: "under_construction",
			is_selected: false,
		},
	] as Array<SearchFilterOptionTextActive>,
	selected_options: [] as Array<SearchFilterOptionText>,
	db_collection: "properties",
	db_field: "availability",
	is_visible: true,
	is_applied: false,
	is_accordion_open: false,
};
