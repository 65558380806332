/*

* This is a helper function that checks if the session as read by the getSession() or useSession() hooks from next-auth/react and the getServerSession() function is valid and ready for use.

^ Accepts @param session: any
^ Optionally accepts @param options?: { enable_logs: boolean; log_context_string: string; }
^ Returns @returns boolean

*/

/*

& Local type and interface definitions

*/

export type LogLevel = "log" | "warn" | "error";

export interface Options {
	enable_logs?: boolean;
	log_level?: LogLevel;
	log_context_string?: string;
}

/*

& Function Definition

*/

const validateClientSession: (session: any, options?: Options) => boolean = (
	session: any,
	{ enable_logs = false, log_level = "error", log_context_string = "" }: Options = {},
): boolean => {
	const epoch: number = Date.now();
	if (session === undefined) {
		if (enable_logs) {
			console[log_level]("LOG CONTEXT => " + log_context_string, "TEST => session === undefined", "BUT IS =>", session);
		}
		return false;
	}
	if (session === null) {
		if (enable_logs) {
			console[log_level]("LOG CONTEXT => " + log_context_string, "TEST => session === null", "BUT IS =>", session);
		}
		return false;
	}
	if (typeof session !== "object") {
		if (enable_logs) {
			console[log_level](
				"LOG CONTEXT => " + log_context_string,
				"TEST => typeof session !== 'object'",
				"BUT IS =>",
				typeof session,
			);
		}
		return false;
	}
	if (typeof session === "object") {
		if (Object.keys(session).length === 0) {
			if (enable_logs) {
				console[log_level](
					"LOG CONTEXT => " + log_context_string,
					"TEST => Object.keys(session).length === 0",
					"BUT IS =>",
					Object.keys(session).length,
				);
			}
			return false;
		}
	}
	if (typeof session.authorized !== "boolean") {
		if (enable_logs) {
			console[log_level](
				"LOG CONTEXT => " + log_context_string,
				"TEST => typeof session.authorized !== 'boolean'",
				"BUT IS =>",
				typeof session.authorized,
			);
		}
		return false;
	}
	if (typeof session.authorized === "boolean") {
		if (session.authorized === false) {
			if (enable_logs) {
				console[log_level](
					"LOG CONTEXT => " + log_context_string,
					"TEST => session.authorized === false",
					"BUT IS =>",
					session.authorized,
				);
			}
			return false;
		}
	}
	if (typeof session.expires_at !== "string") {
		if (enable_logs) {
			console[log_level](
				"LOG CONTEXT => " + log_context_string,
				"TEST => typeof session.expires_at !== 'string'",
				"BUT IS =>",
				typeof session.expires_at,
			);
		}
		return false;
	}
	if (typeof session.expires_at === "string") {
		if (new Date(session.expires_at).getTime() < epoch) {
			if (enable_logs) {
				console[log_level](
					"LOG CONTEXT => " + log_context_string,
					"TEST => new Date(session.expires_at).getTime() < epoch",
					"BUT IS =>",
					"session.expires_at: " + new Date(session.expires_at).toString(),
					"epoch: " + new Date(epoch).toString(),
				);
			}
			return false;
		}
	}
	if (typeof session.session_id !== "string") {
		if (enable_logs) {
			console[log_level](
				"LOG CONTEXT => " + log_context_string,
				"TEST => typeof session.session_id !== 'string'",
				"BUT IS =>",
				typeof session.session_id,
			);
		}
		return false;
	}
	if (typeof session.session_id === "string") {
		if (session.session_id.length === 0) {
			if (enable_logs) {
				console[log_level](
					"LOG CONTEXT => " + log_context_string,
					"TEST => session.session_id.length === 0",
					"BUT IS =>",
					session.session_id.length,
				);
			}
			return false;
		}
	}
	if (typeof session.session_id === "string") {
		if (session.session_id.length !== 0) {
			if (/^[0-9a-fA-F]{24}$/.test(session.session_id) === false) {
				if (enable_logs) {
					console[log_level](
						"LOG CONTEXT => " + log_context_string,
						"TEST => /^[0-9a-fA-F]{24}$/.test(session.session_id) === false",
						"BUT IS =>",
						/^[0-9a-fA-F]{24}$/.test(session.session_id),
						"session.session_id: " + session.session_id,
					);
				}
				return false;
			}
		}
	}
	if (session.user === undefined) {
		if (enable_logs) {
			console[log_level](
				"LOG CONTEXT => " + log_context_string,
				"TEST => session.user === undefined",
				"BUT IS =>",
				session.user,
			);
		}
		return false;
	}
	if (session.user === null) {
		if (enable_logs) {
			console[log_level](
				"LOG CONTEXT => " + log_context_string,
				"TEST => session.user === null",
				"BUT IS =>",
				session.user,
			);
		}
		return false;
	}
	if (typeof session.user !== "object") {
		if (enable_logs) {
			console[log_level](
				"LOG CONTEXT => " + log_context_string,
				"TEST => typeof session.user !== 'object'",
				"BUT IS =>",
				typeof session.user,
			);
		}
		return false;
	}
	if (typeof session.user === "object") {
		if (Object.keys(session.user).length === 0) {
			if (enable_logs) {
				console[log_level](
					"LOG CONTEXT => " + log_context_string,
					"TEST => Object.keys(session.user).length === 0",
					"BUT IS =>",
					Object.keys(session.user).length,
				);
			}
			return false;
		}
	}
	if (typeof session.user.dbUser === undefined) {
		if (enable_logs) {
			console[log_level](
				"LOG CONTEXT => " + log_context_string,
				"TEST => session.user.dbUser === undefined",
				"BUT IS =>",
				session.user.dbUser,
			);
		}
		return false;
	}
	if (typeof session.user.dbUser === null) {
		if (enable_logs) {
			console[log_level](
				"LOG CONTEXT => " + log_context_string,
				"TEST => session.user.dbUser === null",
				"BUT IS =>",
				session.user.dbUser,
			);
		}
		return false;
	}
	if (typeof session.user.dbUser !== "object") {
		if (enable_logs) {
			console[log_level](
				"LOG CONTEXT => " + log_context_string,
				"TEST => typeof session.user.dbUser !== 'object'",
				"BUT IS =>",
				typeof session.user.dbUser,
			);
		}
		return false;
	}
	if (typeof session.user.dbUser === "object") {
		if (Object.keys(session.user.dbUser).length === 0) {
			if (enable_logs) {
				console[log_level](
					"LOG CONTEXT => " + log_context_string,
					"TEST => Object.keys(session.user.dbUser).length === 0",
					"BUT IS =>",
					Object.keys(session.user.dbUser).length,
				);
			}
			return false;
		}
	}
	console[log_level](
		"LOG CONTEXT => " + log_context_string,
		"Client-side session is intact, serializable (redux compliant) and ready for use.",
		"VALIDATED CLIENT SESSION =>",
		session,
	);
	return true;
};

/*

& Default Export

*/

export default validateClientSession;
