import StateInterface from "../state-interface";

export const profile_context: StateInterface["profile_context"] = {
	is_business_profile: false,
	user_id: "",
	business_profile_id: "",
	business_profile_image: {
		file_id: "",
		mime_type: "",
		exists: false,
	},
	page_type: "",
	page_title: "",
	page_slug: "",
	subscription_information: {
		subscription_id: "",
		plan_slug: "",
		end_date: "",
		active: false,
	},
	kyc_info: { kyc_status: "" },
	remember_choice: false,
};
