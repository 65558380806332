import StateInterface from "../state-interface";

export const business_profile_loading: StateInterface["business_profile_loading"] =
  {
    businessProfileImages: true,
    businessProfileFollowers: true,
    businessProfileAboutDetails: true,
    businessProfileServicesOffered: true,
    businessProfileExpertise: true,
    businessProfileWhyChooseUs: true,
    businessProfileBrokerage: true,
    businessProfileJobOpenings: true,
    businessProfileHonorsAndAwards: true,
    businessProfileLicences: true,
    businessProfileEmployees: true,
    businessProfileFaqs: true,
    businessProfileLanguage: true,
    businessProfileListingProperty: true,
    businessProfileReviews: true,
    businessProfileListingService: true,
    businessProfileListingOFB: true,
    buinessProfileLeads: true,
    businessProfileSavedProperty: true,
    businessProfileSavedService: true,
  businessProfileSavedPost: true,
    businessProfileSavedOFB: true,
    businessProfileAnalyticsProperty: true,
    businessProfileAnalyticsService: true,
    businessProfileAnalyticsPost: true,
    businessProfileAnalyticsOFB: true,
    businessProfileAdmin: true,
    businessProfileEmployeeRequest: true,
    businessProfilePost: true,
  };
