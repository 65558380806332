/* State Interface Imports */

import StateInterface from "../state-interface";
import { business_profile } from "./business-profile";
import { property } from "./property";
import { service } from "./service";
import { user } from "./user";

/* Boost Business Profile Page Default State Data */

export const homePage: StateInterface["homePage"] = {
  featured_properties: [0, 0, 0, 0, 0, 0],
  hot_properties: [0, 0, 0, 0, 0, 0],
  top_services: [0, 0, 0, 0, 0, 0],
  trending_services: [0, 0, 0, 0, 0, 0],
  top_users: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  trending_users: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  top_business_profiles: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  trending_business_profiles: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
};
