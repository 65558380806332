/*

? First, let's import the interface that will describe the shape of our object.

*/

import StateInterface from "../state-interface";
import { notification_data } from "./notification";

/*

& Now let's describe an initial state for the object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

const notifications_page_data: StateInterface["notifications_page"] = {
  all_notifications: [],
  unread_notifications_count: 0,
  total_notifications_count: 0,
};

export const notifications_page = notifications_page_data;

export default notifications_page_data;
