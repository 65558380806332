import { SearchFilter, SearchFilterOptionRange } from "../../../redux-magic/sub-interfaces/search-types";

export const _g_security_deposit: SearchFilter = {
	id: "security_deposit",
	title: "Security Deposit",
	level: 2,
	index: 6,
	presentation_type: "range_text_fields",
	multi: false,
	affects: [],
	affected_by: ["transaction_type"],
	priority: 2,
	is_advanced: true,
	included_in_search_bar: false,
	option_type: "range",
	possible_options: [
		{
			id: "security_deposit",
			title: "Security Deposit",
			value: {
				min: 0,
				max: 0,
				unlock_max: true,
			},
		},
	] as Array<SearchFilterOptionRange>,
	active_options: [
		{
			id: "security_deposit",
			title: "Security Deposit",
			value: {
				min: 0,
				max: 0,
				unlock_max: true,
			},
		},
	] as Array<SearchFilterOptionRange>,
	selected_options: [] as Array<SearchFilterOptionRange>,
	db_collection: "properties",
	db_field: "security_deposit",
	is_visible: false,
	is_applied: false,
	is_accordion_open: false,
};
