/*

? First, let's import the interface that will describe the shape of our boost object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the boost object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const report: StateInterface["report"] = {
  _id: "",
  ip_data: {
    ip: "",
    hostname: "",
    type: "",
    continent_code: "",
    continent_name: "",
    country_code: "",
    country_name: "",
    region_code: "",
    region_name: "",
    city: "",
    zip: "",
    latitude: 0,
    longitude: 0,
    location: {
      geoname_id: 0,
      capital: "",
      languages: [
        {
          code: "",
          name: "",
          native: "",
        },
      ],
      country_flag: "",
      country_flag_emoji: "",
      country_flag_emoji_unicode: "",
      calling_code: "",
      is_eu: false,
    },
    time_zone: {
      id: "",
      current_time: new Date(),
      gmt_offset: 0,
      code: "",
      is_daylight_saving: false,
    },
    currency: {
      code: "",
      name: "",
      plural: "",
      symbol: "",
      symbol_native: "",
    },
    connection: {
      asn: "",
      isp: "",
    },
    security: {
      is_proxy: false,
      proxy_type: "",
      is_crawler: false,
      crawler_name: "",
      crawler_type: "",
      is_tor: false,
      threat_level: "",
      threat_types: [""],
    },
  },
  user_agent: {
    ua: "",
    type: "",
    brand: "",
    name: "",
    url: "",
    os: {
      name: "",
      code: "",
      url: "",
      family: "",
      family_code: "",
      family_vendor: "",
      icon: "",
      icon_large: "",
    },
    device: {
      is_mobile_device: false,
      type: "",
      brand: "",
      brand_code: "",
      brand_url: "",
      name: "",
    },
    browser: {
      name: "",
      version: "",
      version_major: "",
      engine: "",
    },
    crawler: {
      is_crawler: false,
      category: "",
      last_seen: "",
    },
  },
  report_type: "",
  description: "",
  created_by: {
    is_business_profile: false,
    user_id: "",
    business_profile_id: "",
  },
  status: "",
  url: "",
  created_at: "",
  updated_at: "",
};
