/*

? First, let's import the interface that will describe the shape of our bidding_cycle object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the bidding_cycle object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const beegru_points: StateInterface["beegru_points"] = {
  user_id: "62097a11b67977b3378b0193",
  status: "Success",
  beegru_points: 50,
  reason: "Debited for boosting",
  transaction_type: "debit",
  created_at: "2021-12-11T06:48:29.323Z",
};
