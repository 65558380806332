import StateInterface from "../state-interface";

export const notification_data: StateInterface["notification"] = {
  _id: "62a1db5fd5605e92d1b8a420",
  title: "",
  description: "",
  notification_type: "",
  entity_type: "",
  entity_id: "",
  redirect_url: "/",
  sender: {
    user_id: "62a1db5fd5605e92d1b8a420",
    business_profile_id: "62a1db5fd5605e92d1b8a420",
    is_business_profile: true,
  },
  receiver: {
    user_id: "62a1db5fd5605e92d1b8a420",
    business_profile_id: "62a1db5fd5605e92d1b8a420",
    is_business_profile: true,
  },
  response: {
    request_response: "accepted",
    responded_at: new Date(),
  },
  viewed: false,
  viewed_at: new Date(),
  created_at: new Date(),
  updated_at: new Date(),
};
