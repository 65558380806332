/* State Interface Imports */

import StateInterface from "../state-interface";
import { cash_transaction } from "./cash-transaction";

/* Receipt Page Default State Data */

export const receipt_page: StateInterface["receipt_page"] = {
  receipt_data: cash_transaction,
  user_data: {
    first_name: "John",
    last_name: "Doe",
    recovery_email: {
      email: "nidalabrar@beegru.com",
      verified: false,
    },
    country_code: "91",
    phone: "8296499130",
    address: {
      line1: "Flat 1, 2nd Floor",
      line2: "Bhagat Singh Colony",
      city: "New Delhi",
      state: "Delhi",
      country: "India",
      pincode: "110094",
      landmark: "Near Metro Station",
    },
  },
};
