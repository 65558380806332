/*

? First, let's import the interface that will describe the shape of our neighbourhood_reviews_page object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the neighbourhood_reviews_page object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const neighbourhood_reviews_page: StateInterface["neighbourhood_reviews_page"] =
  {
    location: "",
    reviews: [],
  };
