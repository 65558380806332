import { SearchFilter, SearchFilterId } from "../../../../redux-magic/sub-interfaces/search-types";
import { SearchFiltersMap, SearchFiltersMapTest } from "../collations";

export const getInitialSearchFilter: (search_filter_id: SearchFilterId) => SearchFilter = (
	search_filter_id: SearchFilterId,
) => {
	return SearchFiltersMap.get(search_filter_id) as SearchFilter;
};

export const getInitialSearchFilterTest: (search_filter_id: SearchFilterId) => SearchFilter = (
	search_filter_id: SearchFilterId,
) => {
	return SearchFiltersMapTest.get(search_filter_id) as SearchFilter;
};
