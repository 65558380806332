/*

? First, let's import the interface that will describe the shape of our beegru_points transaction object.

*/

import { ObjectId } from "mongodb";
import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the beegru_points transaction object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const manual_beegru_points: StateInterface["manual_beegru_points"] = {
  _id: "",
  transaction_amount: 0,
  transaction_type: "credit",
  transaction_reason: "",
  created_by: "64ccae79f0e379d9885f99a0",
  created_at: "2022-06-30T18:30:00.000Z",
  user_id: "64ccae79f0e379d9885f99a0",
};
