import StateInterface from "../state-interface";

export const business_profile_snackbar: StateInterface["business_profile_snackbar"] = {
  upload_cover_image: false,
  edit_cover_image: false,
  delete_cover_image: false,
  upload_profile_picture: false,
  edit_profile_picture: false,
  delete_profile_picture: false,
  update_about: false,
  service_offered: false,
  experties: false,
  why_choose_us: false,
  brokerages: false,
  job_openings: false,
  honors_and_awards: false,
  licences_and_certificates: false,
  no_of_employees: false,
  business_faq: false,
  language: false,
  delete_property_listing: false,
  delete_service_listing: false,
  delete_ofb_listing: false,
  delete_business_profile: false
};