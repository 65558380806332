/*

? First, let's import the interface that will describe the shape of our boost object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the boost object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const activity_log: StateInterface["activity_log"] = {
	_id: "",
	logs: {
		_id_copy: "",
		created_by: {
			is_business_profile: false,
			user_id: "",
			business_profile_id: "",
		},
		action: "",
		entity: {
			entity_id: "",
			entity_type: "",
		},
		url: "",
	},
	action_performed_at: "",
};
