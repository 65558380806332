/*

? First, let's import the interface that will describe the shape of our review object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the review object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const review: StateInterface["review"] = {
  _id: "62b2e32e99a2279a2dd33960",
  search_metrics: {
    search_string: "jayanagarreview",
    search_string_delimiter: "jayanagarreview",
    search_points: 1,
    location_coordinates: [12.903869517218652, 77.5996545779337],
  },
  body: "This is a test review",
  media: [],
  created_by: {
    is_business_profile: false,
    user_id: "624d2a7ffdcb2f276c216f83",
    business_profile_id: "624d2a7ffdcb2f276c216f83",
  },
  rating: 5,
  reviewed_entity_type: "property",
  reviewed_entity_id: "62b2e32e99a2279a2dd33960",
  reviewed_neighbourhood: "JP Nagar",
  upvotes: [],
  downvotes: [],
  views: [],
  tags: ["heavie", "beegru"],
  deleted: false,
  deleted_at: "2022-06-30T18:30:00.000Z",
  created_at: "2022-06-30T18:30:00.000Z",
  updated_at: "2022-06-30T18:30:00.000Z",
};
