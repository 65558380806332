/*

? First, let's import the interface that will describe the shape of our boost object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the boost object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

const extraMins = 1000 * 60 * 60;

export const boost: StateInterface["boost"] = {
	property_id: null,
	boost_modal_state: false,
	manual_override_data: [],
	boost_property_detail: null,
	boost_property_loading: false,
	boost_submit_loading: false,
	boosted_entity_type: "property",
	boosted_entity_id: "",
	boosted_by: {
		is_business_profile: false,
		user_id: "",
		business_profile_id: "",
	},
	boost_location: [],
	boost_start_date: new Date().toISOString(),
	boost_end_date: new Date().toISOString(),
	trigger_start_date: new Date().toISOString(),
	trigger_end_date: new Date(new Date().getTime() + extraMins).toISOString(),
	active: false,
	budget: 0,
	total_amount: 0,
	average_hourly_rate: 0.065,
	created_at: new Date().toISOString(),
	updated_at: new Date().toISOString(),
	cancelled: false,
	cancelled_at: new Date().toISOString(),
};
