/*

? First, let's import the interface that will describe the shape of our beegru_points transaction object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the beegru_points transaction object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const beegru_points_transaction: StateInterface["beegru_points_transaction"] = {
	_id: "",
	transaction: {
		_id_copy: "",
		created_by: {
			is_business_profile: false,
			user_id: "",
			business_profile_id: "",
		},
		transaction_status: "",
		transaction_amount_in_beegru_points: 0,
		transaction_type: "",
		transaction_purpose: "",
		storage_amount: 0,
		transaction_related_entity: {
			entity_type: "",
			entity_id: "",
		},
	},
	created_at: "",
};
