/* State Import */

import StateInterface from "../state-interface";
import { service } from "./service";

/* Service Reviews Page Default Data */

export const service_reviews_page: StateInterface["service_reviews_page"] = {
  service_data: service,
  service_reviews: [],
};
