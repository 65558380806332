/* State Interface Imports */

import StateInterface from "../state-interface";
import { user } from "./user";
import { cash_transaction } from "./cash-transaction";
import { beegru_points_transaction } from "./beegru-points-transaction";
import { subscription_plans } from "./subscription-plans";
import { subscriptions } from "./subscriptions";
import { business_profile } from "./business-profile";

/* Account Page Page Default State Data */

export const account_settings_page: StateInterface["account_settings_page"] = {
	user_data: user,
	user_cash_transactions: [cash_transaction],
	user_beegru_points_transactions: [beegru_points_transaction],
	subscription_plans: [subscription_plans],
	subscriptions: [
		{
			_id: "",
			plan_slug: "",
			subscribed_user_id: "",
			subscribed_entity: "",
			subscribed_entity_id: "",
			start_date: "",
			end_date: "",
			trigger_end_date: "",
			active: false,
			cancelled: false,
			cancelled_at: "",
			created_at: "",
			updated_at: "",
		},
	],
	business_profiles: [business_profile],
	file_counts: {
		videos: 0,
		images: 0,
		documents: 0,
		total: 0,
	},
	file_sizes: {
		videos: 0,
		images: 0,
		documents: 0,
		total: 0,
	},
	file_size_percentages: {
		videos: 0,
		images: 0,
		documents: 0,
		total: 0,
	},
	allocated_storage: 0,
	rewards_transaction: [beegru_points_transaction],
	one_time_rewards: [],
	recurring_rewards: [],
};
