/*

? First, let's import the interface that will describe the shape of our bidding_cycle object.

*/

import { ObjectId } from "bson";
import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the dlt_template object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

const dlt_template_internal: StateInterface["dlt_template"] =
{
  _id: new ObjectId().toString(),
  friendly_identifier: "otp",
  application_id: "7745d8da058243c6899775fdf47541a8",
  configuration_set_name: "Beegru",
  sender_id: "BEEGRU",
  pool_id: "pool-d15fb33254be4b6188d95ed65d9987fc",
  principal_entity_id: "1201160923431641243",
  template_id: "1207170790369152903",
  template_string: "Your Beegru verification code is: {{1}}. Don't share this code with anyone; our employees will never ask for the code.",
  template_variable_index_map: [
    {
      _id: new ObjectId().toString(),
      index: 0,
      variable_name: "otp",
      character_limit: 30,
    },
  ],
  template_type: "TRANSACTIONAL",
  active: true,
  created_at: "2022-06-30T18:30:00.000Z",
  updated_at: "2022-06-30T18:30:00.000Z",
  };

export const dlt_template =
  dlt_template_internal;

export default dlt_template;