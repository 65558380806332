/* State Imports */

import StateInterface from "../state-interface";

export const property_loading: StateInterface["property_loading"] = {
    propertyDetails: true,
    propertyConfigAndArea: true,
    aboutSingleProperty: true,
    singlePropertyCreator: true,
    singlePropertyAboutLocation: true,
    singlePropertyAmenities: true,
    singlePropertyDeveloper: true,
    singlePropertyImages: true,
    singlePropertyLocationCoordinates: true,
    singlePropertyOverview: true,
    singlePropertyReviews: true,
    singlePropertyVicinity: true,
    singlePropertyTrendingProperties: true,
    singlePropertyHotProperties: true,
    singlePropertyNeighbourhoodReviews: true,
}