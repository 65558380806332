/* Common Imports */

import React from "react";

/* Authentication Imports */

import { SessionProvider, signOut } from "next-auth/react";

/* Vercel Analytics Imports */

import { Analytics } from "@vercel/analytics/react";

/* Global CSS Imports */

import "../public/fonts/poppins/poppins.css";
import "../public/styles/animation/agentpropertylist.css";
import "../public/styles/animation/allstarprofessional.css";
import "../public/styles/animation/analyticssucceeding.css";
import "../public/styles/animation/boostcredibility.css";
import "../public/styles/animation/directconnectuser.css";
import "../public/styles/animation/introducepartiesandclaimes.css";
import "../public/styles/animation/listpropertybuilder.css";
import "../public/styles/animation/loading.css";
import "../public/styles/animation/ofbuser.css";
import "../public/styles/animation/personalbranding.css";
import "../public/styles/animation/professionalrecommendation.css";
import "../public/styles/animation/professionalservices.css";
import "../public/styles/animation/rockstarlandowner.css";
import "../public/styles/animation/skyrocketcareer.css";
import "../public/styles/animation/topleadsagents.css";
import "../public/styles/animation/userlocationbased.css";
import "../public/styles/animation/userpropertysearch.css";
import "../public/styles/index.css";

/* Theme Imports */

import { CacheProvider, EmotionCache } from "@emotion/react";
import { PaletteMode, createTheme, useMediaQuery } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { Cookies, CookiesProvider, useCookies } from "react-cookie";
import CreateEmotionCache from "../components/layout-components/CreateEmotionCache";
import GetDesignTokens from "../components/layout-components/Theme";
import ColorModeContext from "../components/layout-components/contexts/colorModeContext";

/* Function Imports */

// import ReceiveMessage from "../lib/ReceiveMessage";

/* Redux Imports */

import { ObjectId } from "bson";
import { Provider } from "react-redux";
import { validateClientSession } from "../lib/auth/helpers";
import { setThemeThunk, wrapper } from "../redux-magic/store";

export function reportWebVitals(metric: any) {}

const clientSideEmotionCache = CreateEmotionCache();

const Marketplace = ({ Component, ...everythingElse }: { Component: any; everythingElse: any }) => {
	const {
		store,
		props: { emotionCache = clientSideEmotionCache, pageProps, themeSetting },
	}: {
		store: any;
		props: {
			emotionCache?: EmotionCache | undefined;
			pageProps: any;
			themeSetting: PaletteMode;
		};
	} = wrapper.useWrappedStore(everythingElse);

	const session: any = pageProps.session ? pageProps.session : pageProps.server_session;

	const [mode, setMode] = React.useState<PaletteMode>(themeSetting || "light");

	const colorMode = React.useMemo(
		() => ({
			toggleColorMode: () => {
				setMode((prevMode: PaletteMode) => {
					const newMode = prevMode === "light" ? "dark" : "light";
					setThemeThunk(newMode);
					return newMode;
				});
			},
		}),
		[],
	);

	const [cookies, setCookie] = useCookies(["cookieColorMode"]);
	const prefersLightMode = useMediaQuery("(prefers-color-scheme: light)");
	const isBrowser = typeof window !== "undefined";

	const addDays = (date: Date, days: number) => {
		const copy = new Date(Number(date));
		copy.setDate(date.getDate() + days);
		return copy;
	};

	React.useEffect(() => {
		if (validateClientSession(session)) {
			setMode(session.user.dbUser.settings.site_preferences.default_theme);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (prefersLightMode && !!cookies.cookieColorMode !== true) {
			setMode("light");
		}
	}, [prefersLightMode, cookies.cookieColorMode]);

	const firstUpdate = React.useRef(true);

	React.useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}
		const date = new Date();
		const expires = addDays(date, 365);
		setCookie("cookieColorMode", mode, { path: "/", expires, secure: true });
	}, [mode, setCookie]);

	React.useEffect(() => {
		const colorSetting = cookies.cookieColorMode;
		if (colorSetting) {
			setThemeThunk(colorSetting as string);
			setMode(colorSetting as PaletteMode);
			if (validateClientSession(session)) {
				const userId: ObjectId = new ObjectId(session.user.dbUser._id);
				fetch(process.env.PRODUCTION_API_URL + "update-theme", {
					method: "POST",
					headers: {
						"Content-Type": "text/plain",
					},
					body: JSON.stringify({
						user_id: userId.toString(),
						theme: colorSetting as string,
					}),
					cache: "no-store",
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cookies.cookieColorMode]);

	const [showInactivityDialog, setShowInactivityDialog] = React.useState(false);

	const [newNotification, setNewNotification] = React.useState(null);

	/* Let's describe a global useEffect to handle sessions */

	let sessionManager = React.useRef<any>();

	const onConnectionOpen = React.useCallback(
		(event: any) => {
			/* As soon as the websocket connection opens, let's register a setInterval to send a heartbeat to the server and keep the session alive */

			setShowInactivityDialog(false);

			const heartbeat = setInterval(
				() => {
					sessionManager?.current?.send(
						JSON.stringify({
							sessionId: session.session_id,
						}),
					);
				},
				parseInt(process.env.SESSION_HEARTBEAT_INTERVAL || "10000"),
			);

			return () => {
				clearInterval(heartbeat);
			};
		},
		[session],
	);

	const onMessage = React.useCallback((event: any) => {
		switch (JSON.parse(event.data.toString()).action) {
			case "invalidate-session":
				signOut({
					callbackUrl: "/",
				});
				break;
			case undefined:
				console.log("%c ❤️~Session~Heartbeat~❤️", "color: #FF0000");
				break;
			case "new-chat-message":
				console.log("receive??");
				// ReceiveMessage(JSON.parse(event.data.toString()).message);
				break;
			case "new-notification":
				setNewNotification(JSON.parse(event.data.toString()).payload);
				break;
			default:
				console.error("Unknown message received from server: ", event.data);
				break;
		}
	}, []);

	const onWebSocketError = React.useCallback((event: any) => {
		/*

    & Ask the user to refresh the page in case of an error through a dialog box.

    */

		setShowInactivityDialog(true);
	}, []);

	React.useEffect(() => {
		if (validateClientSession(session) && !sessionManager.current) {
			sessionManager.current = new WebSocket("wss://session.beegru.com?sessionId=" + session.session_id);
			sessionManager.current.addEventListener("open", onConnectionOpen);
			sessionManager.current.addEventListener("message", onMessage);
			sessionManager.current.addEventListener("error", onWebSocketError);
			return () => {
				sessionManager.current.removeEventListener("open", onConnectionOpen);
				sessionManager.current.removeEventListener("message", onMessage);
				sessionManager.current.removeEventListener("error", onWebSocketError);
			};
		}
	}, [session, onConnectionOpen, onMessage, onWebSocketError]);

	const theme = React.useMemo(() => createTheme(GetDesignTokens(mode)), [mode]);

	return (
		<SessionProvider session={pageProps.session ? pageProps.session : pageProps.server_session}>
			<CacheProvider value={emotionCache}>
				<ColorModeContext.Provider value={colorMode}>
					<ThemeProvider theme={theme}>
						<CssBaseline />
						<CookiesProvider cookies={isBrowser ? undefined : new Cookies(cookies)}>
							<Provider store={store}>
								<Component
									{...pageProps}
									theme={theme}
									showInactivityDialog={showInactivityDialog}
									newNotification={newNotification}
								/>
								<Analytics />
							</Provider>
						</CookiesProvider>
					</ThemeProvider>
				</ColorModeContext.Provider>
			</CacheProvider>
		</SessionProvider>
	);
};

/*

& Let's define a global getInitialProps to validate the session server-side.

^ We're only doing this because _app.ts does not support getStaticProps() or getServerSideProps() yet.

? For more information, visit https://nextjs.org/docs/advanced-features/custom-app#caveats

! This method no longer works as getInitialProps doesn't include the req and res objects in production anymore. This causes any attempts to validate the session server-side to fail. Moving forward, all session validation will be done in getServerSideProps on each page where the session is needed.

*/

/* Let's wrap the entire app with redux to make life easier :) */
/* UPDATE: The new implementation destructures and creates a store within the function definition and therefore a wrapper function is no longer needed. */

export default Marketplace;
