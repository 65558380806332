/*

? First, let's import the interface that will describe the shape of our bidding_cycle object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the bidding_cycle object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/
export const contributions: StateInterface["contributions"] = {
  property_id: "62b2ed6299a2279a2dd33961",
  change_log: [
    {
      _id: "62b2ed6299a2279a2dd33961",
      contributor_id: {
        is_business_profile: false,
        user_id: "62a0a8e45503a8e31dcdab1a",
        business_profile_id: "624d2a7ffdcb2f276c216f83",
      },
      slug: "vaishnavi-terraces",
      approved: false,
      approved_at: "2022-06-30T18:30:00.000Z",
      seo: {
        keywords: ["vaishnavi", "terraces", "apartment", "apartments"],
        meta_title:
          "Luxury Apartments for sale in JP Nagar: Vaishnavi Terraces",
        meta_description:
          "Vaishnavi Terraces by Vaishnavi Group. BDA Approved 3 BHK Luxury Apartments for sale in JP Nagar.",
      },
      title: "Vaishnavi Terraces",
      location: {
        title: "JP Nagar",
        pincode: "560076",
        address_location: [
          "Located in one of Bangalore’s most sought after addresses – Dollars Colony, JP Nagar – Vaishnavi Terraces is right there amidst a host of places of everyday importance. From shopping malls, multiplexes, parks and restaurants to banks, hospitals, schools and commercial centers – everything lies within a short distance, giving you an easy commute and endless convenience.",
        ],
      },
      description: "3.5 BHK luxury Apartments for sale",
      builder: {
        name: "Vaishnavi Group",
        description:
          "Vaishnavi Group designs homes that resonate with the lifestyle needs of Urban Indians. Their projects are conceived using best in class construction technologies and design thinking. Their commitment to deliver a world-class customer experience reflects in their cutting-edge project delivery and customer relationship processes.",
        total_projects: 14,
        ongoing_projects: 2,
        completed_projects: 11,
        upcoming_projects: 1,
        logo: {
          url: "https://bucket.beegru.in/vaishnavilogo.webp",
          caption: "Vaishnavi Group Logo",
        },
      },
      price: {
        min: {
          price: 2.45,
          multiplier: 10000000,
          unit: "Crore",
          calculated: 24500000,
        },
        max: {
          price: 2.45,
          multiplier: 10000000,
          unit: "Crore",
          calculated: 24500000,
        },
        discount: {
          min: {
            price: 2.45,
            multiplier: 10000000,
            unit: "Crore",
            calculated: 24500000,
          },
          max: {
            price: 2.45,
            multiplier: 10000000,
            unit: "Crore",
            calculated: 24500000,
          },
        },
      },
      price_per_unit: {
        min: {
          ft: 1000,
          mt: 107456,
        },
        max: {
          ft: 1000,
          mt: 107456,
        },
      },
      tags: [
        {
          _id: "sale",
          label: "sale",
          icon: "property/sale",
        },
      ],

      area: {
        label: "2455 sqft.",
        enableLabel: false,
        super_built_up_area: {
          min: {
            ft: 2455,
            mt: 228,
          },
          max: {
            ft: 2455,
            mt: 228,
          },
        },
        built_up_area: {
          min: {
            ft: 2455,
            mt: 228,
          },
          max: {
            ft: 2455,
            mt: 228,
          },
        },
        carpet_area: {
          min: {
            ft: 1841,
            mt: 171,
          },
          max: {
            ft: 1841,
            mt: 171,
          },
        },
        karab_area: {
          min: {
            ft: 2455,
            mt: 228,
          },
          max: {
            ft: 2455,
            mt: 228,
          },
        },
      },
      config: {
        parameters: [
          {
            key: "bhk",
            value: ["3.5"],
          },
        ],
        labels: "3.5 BHK",
      },
      bhk: {
        min: 3.5,
        max: 3.5,
        label: "3.5 BHK",
        enableLabel: false,
      },
      bathrooms: {
        min: 2,
        max: 5,
        label: "2.5 Bathrooms",
        enableLabel: false,
      },
      floors: {
        min: 0,
        max: 5,
        label: "0-5",
        enableLabel: false,
      },
      facing: ["south", "west", "east", "north"],
      cover_image: {
        url: "https://bucket.beegru.in/vaishnavi_terrace_cover_image.webp",
        caption: "Vaishnavi Terraces cover image",
      },
      thumbnail_image: {
        url: "https://bucket.beegru.in/vaishnavi_terrace_thumbnail.webp",
        caption: "Vaishnavi Terraces",
      },
      images: [
        {
          url: "Sthttps://bucket.beegru.in/vaishnavi_terrace_1.webpring",
          caption:
            "Aerial View of Vaishnavi Terraces, 3.5 BHK luxury Apartments in JP Nagar",
          category: "category",
          priority: 1,
        },
      ],
      pdf: "https://bucket.beegru.in/pdf/vaishnavi_terrace.pdf",
      about_property: ["BDA Approved", "BBMP Limits"],
      amenities: [
        {
          _id: "swimming_pool",
          label: "swimming_pool",
          icon: "amenities/swimming",
        },
      ],
      approvals: [
        {
          _id: "oc_recieved",
          label: "oc recieved",
          icon: "approvals/oc",
        },
      ],
      vicinity: [
        {
          category: "schools",
          values: [
            {
              title: "Vibgyor High School",
              distance: "1.8 km",
            },
          ],
        },
      ],
      type: ["featured"],
      gmaps_embed_url:
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15556.302663905928!2d77.598268!3d12.902856!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15199fc6e967%3A0x8bb3e0c24a6df98b!2sVaishnavi%20Terrace%2C%20Dollars%20Colony%2C%20Phase%204%2C%20J.%20P.%20Nagar%2C%20Bengaluru%2C%20Karnataka%20560076!5e0!3m2!1sen!2sin!4v1608711353602!5m2!1sen!2sin",
      search_points: 100,
      published: true,
      deleted: "00",
      created_at: "00",
      updated_at: "00",
      contributed_at: "2022-06-22T18:30:00.000+00:00",
      contributed_fields: [],
      change_log: {
        _id: "62b99b399607cb06f9d8f48b",
        updated_at: "2021-12-31T18:30:00.000+00:00",
        changed_sections: "",
      },
    },
  ],
};
