/*

? First, let's import the interface that will describe the shape of our bidding_cycle object.

*/

import { ObjectId } from "bson";
import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the dlt_sms_record object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

const dlt_sms_record_internal: StateInterface["dlt_sms_record"] =
{
  _id: new ObjectId().toString(),
  dlt_template_id: new ObjectId().toString(),
  dlt_template_friendly_identifier: "",
  message_id: "",
  message_body: "",
  message_body_variable_index_map: [],
  recipient: {
    user_id: new ObjectId().toString(),
    country_code: "",
    phone: "",
  },
  status: {
    response_code: 200,
    response_message: "",
    error: false,
  },
  created_at: "2022-06-30T18:30:00.000Z",
  updated_at: "2022-06-30T18:30:00.000Z",
  };

export const dlt_sms_record =
  dlt_sms_record_internal;

export default dlt_sms_record;