/*

? First, let's import the interface that will describe the shape of our bidding_cycle object.

*/

import { ObjectId } from "bson";
import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the dlt_template object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

const dlt_template_internal: StateInterface["dlt_template"] =
{
  _id: new ObjectId().toString(),
  friendly_identifier: "",
  application_id: "",
  configuration_set_name: "",
  sender_id: "",
  pool_id: "",
  principal_entity_id: "",
  template_id: "",
  template_string: "",
  template_variable_index_map: [],
  template_type: "TRANSACTIONAL",
  active: true,
  created_at: "2022-06-30T18:30:00.000Z",
  updated_at: "2022-06-30T18:30:00.000Z",
  };

export const dlt_template =
  dlt_template_internal;

export default dlt_template;