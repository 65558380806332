import { SearchFilter, SearchFilterOptionRange } from "../../../redux-magic/sub-interfaces/search-types";

export const _l_seats: SearchFilter = {
	id: "seats",
	title: "Seats",
	level: 3,
	index: 11,
	presentation_type: "range_text_fields",
	multi: false,
	affects: [],
	affected_by: ["property_type"],
	priority: 3,
	is_advanced: false,
	included_in_search_bar: false,
	option_type: "range",
	possible_options: [
		{
			id: "seats",
			title: "Seats",
			value: {
				min: 0,
				max: 0,
				unlock_max: true,
			},
		},
	] as Array<SearchFilterOptionRange>,
	active_options: [
		{
			id: "seats",
			title: "Seats",
			value: {
				min: 0,
				max: 0,
				unlock_max: true,
			},
		},
	] as Array<SearchFilterOptionRange>,
	selected_options: [] as Array<SearchFilterOptionRange>,
	db_collection: "properties",
	db_field: "no_of_seats",
	is_visible: false,
	is_applied: false,
	is_accordion_open: false,
};
