/*

? First, let's import the interface that will describe the shape of our subscription plans object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the subscription plans object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const subscription_plans: StateInterface["subscription_plans"] = {
  _id: "62e4dcc7893a5fc8c8b287c4",
  plan_slug: "landowner-premium",
  plan_name: "Landowner Premium",
  duration: 2592000,
  features: [
    "Unlimited property listings",
    "Unlimited property reviews",
    "Unlimited property photos",
    "Unlimited property videos",
    "Unlimited property documents",
  ],
  price: 5,
  currency_code: "SGD",
  created_at: "2020-01-01T00:00:00.000Z",
  updated_at: "2020-01-01T00:00:00.000Z",
};
