/*

? First, let's import the interface that will describe the shape of our boost object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the boost object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const properties_price_tracker: StateInterface["properties_price_tracker"] = {
	_id: "",
	change: {
		_id_copy: "",
		property_id: "",
		old_price: 0,
		new_price: 0,
		location_coordinates: [],
		price_difference: 0,
	},
	created_at: "",
};
