/*

? First, let's import the interface that will describe the shape of our bidding_cycle object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the dlt_template object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

const otp_internal: StateInterface["otp"] =
{
    _id: "",
    dlt_sms_record_id: "",
    recipient: {
        user_id: "",
        country_code: "",
        phone: ""
    },
    otp: "",
    verified: {
          _id: "",
          verified: false,
    },
    is_retry: false,
    retry_attempt_number: 0,
    created_at: "",
    updated_at: "",
};

export const otp =
  otp_internal;

export default otp;