/*

? First, let's import the interface that will describe the shape of our subscription plans object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the subscription plans object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const subscription_plans: StateInterface["subscription_plans"] = {
  _id: "",
  plan_slug: "",
  plan_name: "",
  duration: 2592000,
  features: [],
  price: 5,
  currency_code: "",
  created_at: "",
  updated_at: "",
};
