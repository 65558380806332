/*

? First, let's import the interface that will describe the shape of our bidding_cycle object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the bidding_cycle object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const payment: StateInterface["payment"] = {
  user_id: "62097a11b67977b3378b0193",
  amount: 500,
  payment: {
    order_id: "order_9A33XWu170gUtm",
    order_status: "created",
    order_amount: 500,
    order_amount_paid: 0,
    order_amount_due: 500,
    order_currency: "INR",
    order_payment_attempts: 0,
    order_receipt: "order_rcptid_11",
    order_at: "2021-12-11T06:48:29.323Z",
  },
  cookie: "cookie_9A33XWu170gUtm",
  beegru_points: 50,
  currency_code: "INR",
  transaction_reference: "76756756576",
  created_at: "2021-12-11T06:48:29.323Z",
};
