import { SearchFilter, SearchFilterOption } from "../../../../redux-magic/sub-interfaces/search-types";
import {
	agricultural_options,
	commercial_options,
	industrial_options,
	residential_options,
	warehousing_options,
} from "../_e_property_type";
import {
	cleaning_and_maintenance_options,
	construction_options,
	daily_home_care_and_security_options,
	food_options,
	grooming_and_other_personal_care_options,
	home_and_appliance_repair_options,
	home_utilities_options,
	hospitality_options,
	interiors_and_decor_options,
	legal_and_finance_options,
	liaisoning_options,
	other_options,
	social_service_options,
	transport_options,
} from "../_x_service_subtype";
import { SearchFiltersArray } from "../collations";
export const generateRD3TreeDataForSearchFilters = () => {
	const L0SearchFilters: Array<SearchFilter> = SearchFiltersArray.filter(
		(SearchFilter: SearchFilter) => SearchFilter.level === 0,
	);
	const L1SearchFilters: Array<SearchFilter> = SearchFiltersArray.filter(
		(SearchFilter: SearchFilter) => SearchFilter.level === 1,
	);
	const L2SearchFilters: Array<SearchFilter> = SearchFiltersArray.filter(
		(SearchFilter: SearchFilter) => SearchFilter.level === 2,
	);
	const L3SearchFilters: Array<SearchFilter> = SearchFiltersArray.filter(
		(SearchFilter: SearchFilter) => SearchFilter.level === 3,
	);
	const L4SearchFilters: Array<SearchFilter> = SearchFiltersArray.filter(
		(SearchFilter: SearchFilter) => SearchFilter.level === 4,
	);
	const SearchFiltersTreeData = {
		name: "Search Filters",
		children: L0SearchFilters.map((searchFilter: SearchFilter) => ({
			name: searchFilter.title,
			children: searchFilter.possible_options.map((possibleOption: SearchFilterOption) => ({
				name: possibleOption.title,
				children: L1SearchFilters.map((searchFilter: SearchFilter) => {
					if (possibleOption.id === "properties") {
						if (!searchFilter.id.includes("service")) {
							return {
								name: searchFilter.title,
								children: searchFilter.possible_options.map((possibleOption: SearchFilterOption) => ({
									name: possibleOption.title,
									children: L2SearchFilters.map((searchFilter: SearchFilter) => {
										switch (possibleOption.id) {
											case "rent":
												if (searchFilter.id === "security_deposit") {
													return {
														name: searchFilter.title,
													};
												} else {
													return null;
												}
											case "residential":
												if (searchFilter.id === "property_type") {
													return {
														name: searchFilter.title,
														children: residential_options.map((residential_option: SearchFilterOption) => ({
															name: residential_option.title,
															children: L3SearchFilters.map((searchFilter: SearchFilter) => {
																switch (residential_option.id) {
																	case "plot":
																		if (
																			[
																				"area",
																				"plot_dimensions",
																				"property_status",
																				"unit_facing",
																				"amenities",
																			].includes(searchFilter.id)
																		) {
																			return {
																				name: searchFilter.title,
																				children: searchFilter.possible_options
																					.map((possibleOption: SearchFilterOption) => {
																						if (searchFilter.id === "property_status") {
																							return {
																								name: possibleOption.title,
																								children: L4SearchFilters.map((searchFilter: SearchFilter) => {
																									switch (possibleOption.id) {
																										case "under_construction":
																											if (searchFilter.id === "possession_date") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										case "select_date":
																											if (searchFilter.id === "available_from") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										default:
																											return null;
																									}
																								}).filter((node: any) => node !== null) as any,
																							};
																						} else {
																							return null;
																						}
																					})
																					.filter((node: any) => node !== null) as any,
																			};
																		} else {
																			return null;
																		}
																	case "row_house":
																		if (
																			[
																				"bedrooms",
																				"area",
																				"bathrooms",
																				"balconies",
																				"property_status",
																				"unit_facing",
																				"floors",
																				"furnishing",
																				"amenities",
																			].includes(searchFilter.id)
																		) {
																			return {
																				name: searchFilter.title,
																				children: searchFilter.possible_options
																					.map((possibleOption: SearchFilterOption) => {
																						if (searchFilter.id === "property_status") {
																							return {
																								name: possibleOption.title,
																								children: L4SearchFilters.map((searchFilter: SearchFilter) => {
																									switch (possibleOption.id) {
																										case "under_construction":
																											if (searchFilter.id === "possession_date") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										case "select_date":
																											if (searchFilter.id === "available_from") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										default:
																											return null;
																									}
																								}).filter((node: any) => node !== null) as any,
																							};
																						} else {
																							return null;
																						}
																					})
																					.filter((node: any) => node !== null) as any,
																			};
																		} else {
																			return null;
																		}
																	case "co_living":
																		if (
																			[
																				"bedrooms",
																				"area",
																				"property_status",
																				"unit_facing",
																				"floors",
																				"furnishing",
																				"amenities",
																			].includes(searchFilter.id)
																		) {
																			return {
																				name: searchFilter.title,
																				children: searchFilter.possible_options
																					.map((possibleOption: SearchFilterOption) => {
																						if (searchFilter.id === "property_status") {
																							return {
																								name: possibleOption.title,
																								children: L4SearchFilters.map((searchFilter: SearchFilter) => {
																									switch (possibleOption.id) {
																										case "under_construction":
																											if (searchFilter.id === "possession_date") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										case "select_date":
																											if (searchFilter.id === "available_from") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										default:
																											return null;
																									}
																								}).filter((node: any) => node !== null) as any,
																							};
																						} else {
																							return null;
																						}
																					})
																					.filter((node: any) => node !== null) as any,
																			};
																		} else {
																			return null;
																		}
																	case "land_residential":
																		if (["area", "land_facing"].includes(searchFilter.id)) {
																			return {
																				name: searchFilter.title,
																			};
																		} else {
																			return null;
																		}
																	case "apartment":
																		if (
																			[
																				"bedrooms",
																				"area",
																				"bathrooms",
																				"balconies",
																				"property_status",
																				"unit_facing",
																				"floors",
																				"furnishing",
																				"amenities",
																			].includes(searchFilter.id)
																		) {
																			return {
																				name: searchFilter.title,
																				children: searchFilter.possible_options
																					.map((possibleOption: SearchFilterOption) => {
																						if (searchFilter.id === "property_status") {
																							return {
																								name: possibleOption.title,
																								children: L4SearchFilters.map((searchFilter: SearchFilter) => {
																									switch (possibleOption.id) {
																										case "under_construction":
																											if (searchFilter.id === "possession_date") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										case "select_date":
																											if (searchFilter.id === "available_from") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										default:
																											return null;
																									}
																								}).filter((node: any) => node !== null) as any,
																							};
																						} else {
																							return null;
																						}
																					})
																					.filter((node: any) => node !== null) as any,
																			};
																		} else {
																			return null;
																		}
																	case "penthouse":
																		if (
																			[
																				"bedrooms",
																				"area",
																				"bathrooms",
																				"balconies",
																				"property_status",
																				"unit_facing",
																				"floors",
																				"furnishing",
																				"amenities",
																			].includes(searchFilter.id)
																		) {
																			return {
																				name: searchFilter.title,
																				children: searchFilter.possible_options
																					.map((possibleOption: SearchFilterOption) => {
																						if (searchFilter.id === "property_status") {
																							return {
																								name: possibleOption.title,
																								children: L4SearchFilters.map((searchFilter: SearchFilter) => {
																									switch (possibleOption.id) {
																										case "under_construction":
																											if (searchFilter.id === "possession_date") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										case "select_date":
																											if (searchFilter.id === "available_from") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										default:
																											return null;
																									}
																								}).filter((node: any) => node !== null) as any,
																							};
																						} else {
																							return null;
																						}
																					})
																					.filter((node: any) => node !== null) as any,
																			};
																		} else {
																			return null;
																		}
																	case "independent_house":
																		if (
																			[
																				"bedrooms",
																				"area",
																				"bathrooms",
																				"balconies",
																				"property_status",
																				"unit_facing",
																				"floors",
																				"furnishing",
																				"amenities",
																			].includes(searchFilter.id)
																		) {
																			return {
																				name: searchFilter.title,
																				children: searchFilter.possible_options
																					.map((possibleOption: SearchFilterOption) => {
																						if (searchFilter.id === "property_status") {
																							return {
																								name: possibleOption.title,
																								children: L4SearchFilters.map((searchFilter: SearchFilter) => {
																									switch (possibleOption.id) {
																										case "under_construction":
																											if (searchFilter.id === "possession_date") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										case "select_date":
																											if (searchFilter.id === "available_from") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										default:
																											return null;
																									}
																								}).filter((node: any) => node !== null) as any,
																							};
																						} else {
																							return null;
																						}
																					})
																					.filter((node: any) => node !== null) as any,
																			};
																		} else {
																			return null;
																		}
																	case "villa":
																		if (
																			[
																				"bedrooms",
																				"area",
																				"bathrooms",
																				"balconies",
																				"property_status",
																				"unit_facing",
																				"floors",
																				"furnishing",
																				"amenities",
																			].includes(searchFilter.id)
																		) {
																			return {
																				name: searchFilter.title,
																				children: searchFilter.possible_options
																					.map((possibleOption: SearchFilterOption) => {
																						if (searchFilter.id === "property_status") {
																							return {
																								name: possibleOption.title,
																								children: L4SearchFilters.map((searchFilter: SearchFilter) => {
																									switch (possibleOption.id) {
																										case "under_construction":
																											if (searchFilter.id === "possession_date") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										case "select_date":
																											if (searchFilter.id === "available_from") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										default:
																											return null;
																									}
																								}).filter((node: any) => node !== null) as any,
																							};
																						} else {
																							return null;
																						}
																					})
																					.filter((node: any) => node !== null) as any,
																			};
																		} else {
																			return null;
																		}
																	case "builder_floor_apartment":
																		if (
																			[
																				"bedrooms",
																				"area",
																				"bathrooms",
																				"balconies",
																				"property_status",
																				"unit_facing",
																				"floors",
																				"furnishing",
																				"amenities",
																			].includes(searchFilter.id)
																		) {
																			return {
																				name: searchFilter.title,
																				children: searchFilter.possible_options
																					.map((possibleOption: SearchFilterOption) => {
																						if (searchFilter.id === "property_status") {
																							return {
																								name: possibleOption.title,
																								children: L4SearchFilters.map((searchFilter: SearchFilter) => {
																									switch (possibleOption.id) {
																										case "under_construction":
																											if (searchFilter.id === "possession_date") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										case "select_date":
																											if (searchFilter.id === "available_from") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										default:
																											return null;
																									}
																								}).filter((node: any) => node !== null) as any,
																							};
																						} else {
																							return null;
																						}
																					})
																					.filter((node: any) => node !== null) as any,
																			};
																		} else {
																			return null;
																		}
																	default:
																		return null;
																}
															}).filter((node: any) => node !== null) as any,
														})),
													};
												} else {
													return null;
												}
											case "commercial":
												if (searchFilter.id === "property_type") {
													return {
														name: searchFilter.title,
														children: commercial_options.map((commercial_option: SearchFilterOption) => ({
															name: commercial_option.title,
															children: L3SearchFilters.map((searchFilter: SearchFilter) => {
																switch (commercial_option.id) {
																	case "sez":
																		if (
																			[
																				"area",
																				"seats",
																				"property_status",
																				"unit_facing",
																				"land_facing",
																				"floors",
																				"furnishing",
																				"amenities",
																			].includes(searchFilter.id)
																		) {
																			return {
																				name: searchFilter.title,
																				children: searchFilter.possible_options
																					.map((possibleOption: SearchFilterOption) => {
																						if (searchFilter.id === "property_status") {
																							return {
																								name: possibleOption.title,
																								children: L4SearchFilters.map((searchFilter: SearchFilter) => {
																									switch (possibleOption.id) {
																										case "under_construction":
																											if (searchFilter.id === "possession_date") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										case "select_date":
																											if (searchFilter.id === "available_from") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										default:
																											return null;
																									}
																								}).filter((node: any) => node !== null) as any,
																							};
																						} else {
																							return null;
																						}
																					})
																					.filter((node: any) => node !== null) as any,
																			};
																		} else {
																			return null;
																		}
																	case "educational":
																		if (
																			[
																				"area",
																				"property_status",
																				"unit_facing",
																				"floors",
																				"furnishing",
																				"amenities",
																			].includes(searchFilter.id)
																		) {
																			return {
																				name: searchFilter.title,
																				children: searchFilter.possible_options
																					.map((possibleOption: SearchFilterOption) => {
																						if (searchFilter.id === "property_status") {
																							return {
																								name: possibleOption.title,
																								children: L4SearchFilters.map((searchFilter: SearchFilter) => {
																									switch (possibleOption.id) {
																										case "under_construction":
																											if (searchFilter.id === "possession_date") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										case "select_date":
																											if (searchFilter.id === "available_from") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										default:
																											return null;
																									}
																								}).filter((node: any) => node !== null) as any,
																							};
																						} else {
																							return null;
																						}
																					})
																					.filter((node: any) => node !== null) as any,
																			};
																		} else {
																			return null;
																		}
																	case "co_working":
																		if (
																			[
																				"area",
																				"seats",
																				"property_status",
																				"unit_facing",
																				"floors",
																				"amenities",
																			].includes(searchFilter.id)
																		) {
																			return {
																				name: searchFilter.title,
																				children: searchFilter.possible_options
																					.map((possibleOption: SearchFilterOption) => {
																						if (searchFilter.id === "property_status") {
																							return {
																								name: possibleOption.title,
																								children: L4SearchFilters.map((searchFilter: SearchFilter) => {
																									switch (possibleOption.id) {
																										case "under_construction":
																											if (searchFilter.id === "possession_date") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										case "select_date":
																											if (searchFilter.id === "available_from") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										default:
																											return null;
																									}
																								}).filter((node: any) => node !== null) as any,
																							};
																						} else {
																							return null;
																						}
																					})
																					.filter((node: any) => node !== null) as any,
																			};
																		} else {
																			return null;
																		}
																	case "land_commercial":
																		if (["area", "land_facing"].includes(searchFilter.id)) {
																			return {
																				name: searchFilter.title,
																			};
																		} else {
																			return null;
																		}
																	case "building":
																		if (
																			[
																				"area",
																				"property_status",
																				"unit_facing",
																				"floors",
																				"furnishing",
																				"amenities",
																			].includes(searchFilter.id)
																		) {
																			return {
																				name: searchFilter.title,
																				children: searchFilter.possible_options
																					.map((possibleOption: SearchFilterOption) => {
																						if (searchFilter.id === "property_status") {
																							return {
																								name: possibleOption.title,
																								children: L4SearchFilters.map((searchFilter: SearchFilter) => {
																									switch (possibleOption.id) {
																										case "under_construction":
																											if (searchFilter.id === "possession_date") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										case "select_date":
																											if (searchFilter.id === "available_from") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										default:
																											return null;
																									}
																								}).filter((node: any) => node !== null) as any,
																							};
																						} else {
																							return null;
																						}
																					})
																					.filter((node: any) => node !== null) as any,
																			};
																		} else {
																			return null;
																		}
																	case "office_space":
																		if (
																			[
																				"area",
																				"property_status",
																				"unit_facing",
																				"floors",
																				"furnishing",
																				"amenities",
																			].includes(searchFilter.id)
																		) {
																			return {
																				name: searchFilter.title,
																				children: searchFilter.possible_options
																					.map((possibleOption: SearchFilterOption) => {
																						if (searchFilter.id === "property_status") {
																							return {
																								name: possibleOption.title,
																								children: L4SearchFilters.map((searchFilter: SearchFilter) => {
																									switch (possibleOption.id) {
																										case "under_construction":
																											if (searchFilter.id === "possession_date") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										case "select_date":
																											if (searchFilter.id === "available_from") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										default:
																											return null;
																									}
																								}).filter((node: any) => node !== null) as any,
																							};
																						} else {
																							return null;
																						}
																					})
																					.filter((node: any) => node !== null) as any,
																			};
																		} else {
																			return null;
																		}
																	case "retail":
																		if (
																			[
																				"area",
																				"property_status",
																				"unit_facing",
																				"floors",
																				"furnishing",
																				"amenities",
																			].includes(searchFilter.id)
																		) {
																			return {
																				name: searchFilter.title,
																				children: searchFilter.possible_options
																					.map((possibleOption: SearchFilterOption) => {
																						if (searchFilter.id === "property_status") {
																							return {
																								name: possibleOption.title,
																								children: L4SearchFilters.map((searchFilter: SearchFilter) => {
																									switch (possibleOption.id) {
																										case "under_construction":
																											if (searchFilter.id === "possession_date") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										case "select_date":
																											if (searchFilter.id === "available_from") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										default:
																											return null;
																									}
																								}).filter((node: any) => node !== null) as any,
																							};
																						} else {
																							return null;
																						}
																					})
																					.filter((node: any) => node !== null) as any,
																			};
																		} else {
																			return null;
																		}
																	case "hospitality":
																		if (
																			[
																				"area",
																				"property_status",
																				"unit_facing",
																				"floors",
																				"furnishing",
																				"amenities",
																			].includes(searchFilter.id)
																		) {
																			return {
																				name: searchFilter.title,
																				children: searchFilter.possible_options
																					.map((possibleOption: SearchFilterOption) => {
																						if (searchFilter.id === "property_status") {
																							return {
																								name: possibleOption.title,
																								children: L4SearchFilters.map((searchFilter: SearchFilter) => {
																									switch (possibleOption.id) {
																										case "under_construction":
																											if (searchFilter.id === "possession_date") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										case "select_date":
																											if (searchFilter.id === "available_from") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										default:
																											return null;
																									}
																								}).filter((node: any) => node !== null) as any,
																							};
																						} else {
																							return null;
																						}
																					})
																					.filter((node: any) => node !== null) as any,
																			};
																		} else {
																			return null;
																		}
																	case "healthcare":
																		if (
																			[
																				"area",
																				"property_status",
																				"unit_facing",
																				"floors",
																				"furnishing",
																				"amenities",
																			].includes(searchFilter.id)
																		) {
																			return {
																				name: searchFilter.title,
																				children: searchFilter.possible_options
																					.map((possibleOption: SearchFilterOption) => {
																						if (searchFilter.id === "property_status") {
																							return {
																								name: possibleOption.title,
																								children: L4SearchFilters.map((searchFilter: SearchFilter) => {
																									switch (possibleOption.id) {
																										case "under_construction":
																											if (searchFilter.id === "possession_date") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										case "select_date":
																											if (searchFilter.id === "available_from") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										default:
																											return null;
																									}
																								}).filter((node: any) => node !== null) as any,
																							};
																						} else {
																							return null;
																						}
																					})
																					.filter((node: any) => node !== null) as any,
																			};
																		} else {
																			return null;
																		}
																	default:
																		return null;
																}
															}).filter((node: any) => node !== null) as any,
														})),
													};
												} else {
													return null;
												}
											case "agricultural":
												if (searchFilter.id === "property_type") {
													return {
														name: searchFilter.title,
														children: agricultural_options.map((agricultural_option: SearchFilterOption) => ({
															name: agricultural_option.title,
															children: L3SearchFilters.map((searchFilter: SearchFilter) => {
																switch (agricultural_option.id) {
																	case "land_agricultural":
																		if (["area", "land_facing"].includes(searchFilter.id)) {
																			return {
																				name: searchFilter.title,
																			};
																		} else {
																			return null;
																		}
																	case "farm_plot":
																		if (
																			[
																				"area",
																				"plot_dimensions",
																				"property_status",
																				"unit_facing",
																				"amenities",
																			].includes(searchFilter.id)
																		) {
																			return {
																				name: searchFilter.title,
																				children: searchFilter.possible_options
																					.map((possibleOption: SearchFilterOption) => {
																						if (searchFilter.id === "property_status") {
																							return {
																								name: possibleOption.title,
																								children: L4SearchFilters.map((searchFilter: SearchFilter) => {
																									switch (possibleOption.id) {
																										case "under_construction":
																											if (searchFilter.id === "possession_date") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										case "select_date":
																											if (searchFilter.id === "available_from") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										default:
																											return null;
																									}
																								}).filter((node: any) => node !== null) as any,
																							};
																						} else {
																							return null;
																						}
																					})
																					.filter((node: any) => node !== null) as any,
																			};
																		} else {
																			return null;
																		}
																	case "farm_house":
																		if (
																			[
																				"bedrooms",
																				"area",
																				"bathrooms",
																				"balconies",
																				"property_status",
																				"unit_facing",
																				"floors",
																				"furnishing",
																				"amenities",
																			].includes(searchFilter.id)
																		) {
																			return {
																				name: searchFilter.title,
																				children: searchFilter.possible_options
																					.map((possibleOption: SearchFilterOption) => {
																						if (searchFilter.id === "property_status") {
																							return {
																								name: possibleOption.title,
																								children: L4SearchFilters.map((searchFilter: SearchFilter) => {
																									switch (possibleOption.id) {
																										case "under_construction":
																											if (searchFilter.id === "possession_date") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										case "select_date":
																											if (searchFilter.id === "available_from") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										default:
																											return null;
																									}
																								}).filter((node: any) => node !== null) as any,
																							};
																						} else {
																							return null;
																						}
																					})
																					.filter((node: any) => node !== null) as any,
																			};
																		} else {
																			return null;
																		}
																	default:
																		return null;
																}
															}).filter((node: any) => node !== null) as any,
														})),
													};
												} else {
													return null;
												}
											case "warehousing":
												if (searchFilter.id === "property_type") {
													return {
														name: searchFilter.title,
														children: warehousing_options.map((warehousing_option: SearchFilterOption) => ({
															name: warehousing_option.title,
															children: L3SearchFilters.map((searchFilter: SearchFilter) => {
																switch (warehousing_option.id) {
																	case "warehouse_industrial":
																		if (
																			["area", "property_status", "land_facing", "amenities"].includes(searchFilter.id)
																		) {
																			return {
																				name: searchFilter.title,
																				children: searchFilter.possible_options
																					.map((possibleOption: SearchFilterOption) => {
																						if (searchFilter.id === "property_status") {
																							return {
																								name: possibleOption.title,
																								children: L4SearchFilters.map((searchFilter: SearchFilter) => {
																									switch (possibleOption.id) {
																										case "under_construction":
																											if (searchFilter.id === "possession_date") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										case "select_date":
																											if (searchFilter.id === "available_from") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										default:
																											return null;
																									}
																								}).filter((node: any) => node !== null) as any,
																							};
																						} else {
																							return null;
																						}
																					})
																					.filter((node: any) => node !== null) as any,
																			};
																		} else {
																			return null;
																		}
																	case "warehouse_commercial":
																		if (
																			["area", "property_status", "land_facing", "amenities"].includes(searchFilter.id)
																		) {
																			return {
																				name: searchFilter.title,
																				children: searchFilter.possible_options
																					.map((possibleOption: SearchFilterOption) => {
																						if (searchFilter.id === "property_status") {
																							return {
																								name: possibleOption.title,
																								children: L4SearchFilters.map((searchFilter: SearchFilter) => {
																									switch (possibleOption.id) {
																										case "under_construction":
																											if (searchFilter.id === "possession_date") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										case "select_date":
																											if (searchFilter.id === "available_from") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										default:
																											return null;
																									}
																								}).filter((node: any) => node !== null) as any,
																							};
																						} else {
																							return null;
																						}
																					})
																					.filter((node: any) => node !== null) as any,
																			};
																		} else {
																			return null;
																		}
																	case "warehouse_cold_storage":
																		if (
																			["area", "property_status", "land_facing", "amenities"].includes(searchFilter.id)
																		) {
																			return {
																				name: searchFilter.title,
																				children: searchFilter.possible_options
																					.map((possibleOption: SearchFilterOption) => {
																						if (searchFilter.id === "property_status") {
																							return {
																								name: possibleOption.title,
																								children: L4SearchFilters.map((searchFilter: SearchFilter) => {
																									switch (possibleOption.id) {
																										case "under_construction":
																											if (searchFilter.id === "possession_date") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										case "select_date":
																											if (searchFilter.id === "available_from") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										default:
																											return null;
																									}
																								}).filter((node: any) => node !== null) as any,
																							};
																						} else {
																							return null;
																						}
																					})
																					.filter((node: any) => node !== null) as any,
																			};
																		} else {
																			return null;
																		}
																	case "warehouse_other":
																		if (
																			["area", "property_status", "land_facing", "amenities"].includes(searchFilter.id)
																		) {
																			return {
																				name: searchFilter.title,
																				children: searchFilter.possible_options
																					.map((possibleOption: SearchFilterOption) => {
																						if (searchFilter.id === "property_status") {
																							return {
																								name: possibleOption.title,
																								children: L4SearchFilters.map((searchFilter: SearchFilter) => {
																									switch (possibleOption.id) {
																										case "under_construction":
																											if (searchFilter.id === "possession_date") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										case "select_date":
																											if (searchFilter.id === "available_from") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										default:
																											return null;
																									}
																								}).filter((node: any) => node !== null) as any,
																							};
																						} else {
																							return null;
																						}
																					})
																					.filter((node: any) => node !== null) as any,
																			};
																		} else {
																			return null;
																		}
																	default:
																		return null;
																}
															}).filter((node: any) => node !== null) as any,
														})),
													};
												} else {
													return null;
												}
											case "industrial":
												if (searchFilter.id === "property_type") {
													return {
														name: searchFilter.title,
														children: industrial_options.map((industrial_option: SearchFilterOption) => ({
															name: industrial_option.title,
															children: L3SearchFilters.map((searchFilter: SearchFilter) => {
																switch (industrial_option.id) {
																	case "factory":
																		if (
																			["area", "property_status", "land_facing", "amenities"].includes(searchFilter.id)
																		) {
																			return {
																				name: searchFilter.title,
																				children: searchFilter.possible_options
																					.map((possibleOption: SearchFilterOption) => {
																						if (searchFilter.id === "property_status") {
																							return {
																								name: possibleOption.title,
																								children: L4SearchFilters.map((searchFilter: SearchFilter) => {
																									switch (possibleOption.id) {
																										case "under_construction":
																											if (searchFilter.id === "possession_date") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										case "select_date":
																											if (searchFilter.id === "available_from") {
																												return {
																													name: searchFilter.title,
																												};
																											} else {
																												return null;
																											}
																										default:
																											return null;
																									}
																								}).filter((node: any) => node !== null) as any,
																							};
																						} else {
																							return null;
																						}
																					})
																					.filter((node: any) => node !== null) as any,
																			};
																		} else {
																			return null;
																		}
																	case "land_industrial":
																		if (["area", "land_facing"].includes(searchFilter.id)) {
																			return {
																				name: searchFilter.title,
																			};
																		} else {
																			return null;
																		}
																	case "mining":
																		if (["area", "land_facing", "amenities"].includes(searchFilter.id)) {
																			return {
																				name: searchFilter.title,
																			};
																		} else {
																			return null;
																		}
																	default:
																		return null;
																}
															}).filter((node: any) => node !== null) as any,
														})),
													};
												} else {
													return null;
												}
											default:
												return null;
										}
									}).filter((node: any) => node !== null) as any,
								})),
							};
						} else {
							return null;
						}
					} else if (possibleOption.id === "services") {
						if (!searchFilter.id.includes("property") && !searchFilter.id.includes("transaction_type")) {
							return {
								name: searchFilter.title,
								children: searchFilter.possible_options.map((possibleOption: SearchFilterOption) => ({
									name: possibleOption.title,
									children: L2SearchFilters.map((searchFilter: SearchFilter) => {
										if (possibleOption.id === "regular_service") {
											if (searchFilter.id === "service_type") {
												return {
													name: searchFilter.title,
													children: searchFilter.possible_options.map((possibleOption: SearchFilterOption) => ({
														name: possibleOption.title,
														children: L3SearchFilters.map((searchFilter: SearchFilter) => {
															switch (possibleOption.id) {
																case "construction":
																	if (searchFilter.id === "service_subtype") {
																		return {
																			name: searchFilter.title,
																			children: construction_options.map((construction_option: SearchFilterOption) => ({
																				name: construction_option.title,
																			})),
																		};
																	} else {
																		return null;
																	}
																case "cleaning_and_maintenance":
																	if (searchFilter.id === "service_subtype") {
																		return {
																			name: searchFilter.title,
																			children: cleaning_and_maintenance_options.map(
																				(cleaning_and_maintenance_option: SearchFilterOption) => ({
																					name: cleaning_and_maintenance_option.title,
																				}),
																			),
																		};
																	} else {
																		return null;
																	}
																case "interiors_and_decor":
																	if (searchFilter.id === "service_subtype") {
																		return {
																			name: searchFilter.title,
																			children: interiors_and_decor_options.map(
																				(interiors_and_decor_option: SearchFilterOption) => ({
																					name: interiors_and_decor_option.title,
																				}),
																			),
																		};
																	} else {
																		return null;
																	}
																case "home_utilities":
																	if (searchFilter.id === "service_subtype") {
																		return {
																			name: searchFilter.title,
																			children: home_utilities_options.map(
																				(home_utilities_option: SearchFilterOption) => ({
																					name: home_utilities_option.title,
																				}),
																			),
																		};
																	} else {
																		return null;
																	}
																case "daily_home_care_and_security":
																	if (searchFilter.id === "service_subtype") {
																		return {
																			name: searchFilter.title,
																			children: daily_home_care_and_security_options.map(
																				(daily_home_care_and_security_option: SearchFilterOption) => ({
																					name: daily_home_care_and_security_option.title,
																				}),
																			),
																		};
																	} else {
																		return null;
																	}
																case "home_and_appliance_repair":
																	if (searchFilter.id === "service_subtype") {
																		return {
																			name: searchFilter.title,
																			children: home_and_appliance_repair_options.map(
																				(home_and_appliance_repair_option: SearchFilterOption) => ({
																					name: home_and_appliance_repair_option.title,
																				}),
																			),
																		};
																	} else {
																		return null;
																	}
																case "transport":
																	if (searchFilter.id === "service_subtype") {
																		return {
																			name: searchFilter.title,
																			children: transport_options.map((transport_option: SearchFilterOption) => ({
																				name: transport_option.title,
																			})),
																		};
																	} else {
																		return null;
																	}
																case "legal_and_finance":
																	if (searchFilter.id === "service_subtype") {
																		return {
																			name: searchFilter.title,
																			children: legal_and_finance_options.map(
																				(legal_and_finance_option: SearchFilterOption) => ({
																					name: legal_and_finance_option.title,
																				}),
																			),
																		};
																	} else {
																		return null;
																	}
																case "grooming_and_other_personal_care":
																	if (searchFilter.id === "service_subtype") {
																		return {
																			name: searchFilter.title,
																			children: grooming_and_other_personal_care_options.map(
																				(grooming_and_other_personal_care_option: SearchFilterOption) => ({
																					name: grooming_and_other_personal_care_option.title,
																				}),
																			),
																		};
																	} else {
																		return null;
																	}
																case "liaisoning":
																	if (searchFilter.id === "service_subtype") {
																		return {
																			name: searchFilter.title,
																			children: liaisoning_options.map((liaisoning_option: SearchFilterOption) => ({
																				name: liaisoning_option.title,
																			})),
																		};
																	} else {
																		return null;
																	}
																case "social_service":
																	if (searchFilter.id === "service_subtype") {
																		return {
																			name: searchFilter.title,
																			children: social_service_options.map(
																				(social_service_option: SearchFilterOption) => ({
																					name: social_service_option.title,
																				}),
																			),
																		};
																	} else {
																		return null;
																	}
																case "food":
																	if (searchFilter.id === "service_subtype") {
																		return {
																			name: searchFilter.title,
																			children: food_options.map((food_option: SearchFilterOption) => ({
																				name: food_option.title,
																			})),
																		};
																	} else {
																		return null;
																	}
																case "hospitality":
																	if (searchFilter.id === "service_subtype") {
																		return {
																			name: searchFilter.title,
																			children: hospitality_options.map((hospitality_option: SearchFilterOption) => ({
																				name: hospitality_option.title,
																			})),
																		};
																	} else {
																		return null;
																	}
																case "other":
																	if (searchFilter.id === "service_subtype") {
																		return {
																			name: searchFilter.title,
																			children: other_options.map((other_option: SearchFilterOption) => ({
																				name: other_option.title,
																			})),
																		};
																	} else {
																		return null;
																	}
																default:
																	return null;
															}
														}).filter((node: any) => node !== null) as any,
													})),
												};
											} else {
												return null;
											}
										} else {
											return null;
										}
									}).filter((node: any) => node !== null) as any,
								})),
							};
						} else {
							return null;
						}
					} else {
						return null;
					}
				}).filter((node: any) => node !== null) as any,
			})),
		})),
	};
	return SearchFiltersTreeData;
};
