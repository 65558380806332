import {
	SearchFilter,
	SearchFilterOptionText,
	SearchFilterOptionTextActive,
} from "../../../redux-magic/sub-interfaces/search-types";

export const _r_land_facing: SearchFilter = {
	id: "land_facing",
	title: "Facing",
	level: 3,
	index: 17,
	presentation_type: "chips",
	multi: true,
	affects: [],
	affected_by: ["property_type"],
	priority: 3,
	is_advanced: true,
	included_in_search_bar: true,
	option_type: "text",
	possible_options: [
		{
			id: "north",
			title: "North",
			value: "north",
		},
		{
			id: "south",
			title: "South",
			value: "south",
		},
		{
			id: "east",
			title: "East",
			value: "east",
		},
		{
			id: "west",
			title: "West",
			value: "west",
		},
		{
			id: "north_east",
			title: "North east",
			value: "north_east",
		},
		{
			id: "north_west",
			title: "North west",
			value: "north_west",
		},
		{
			id: "south_east",
			title: "South east",
			value: "south_east",
		},
		{
			id: "south_west",
			title: "South west",
			value: "south_west",
		},
	] as Array<SearchFilterOptionText>,
	active_options: [
		{
			id: "north",
			title: "North",
			value: "north",
			is_selected: false,
		},
		{
			id: "south",
			title: "South",
			value: "south",
			is_selected: false,
		},
		{
			id: "east",
			title: "East",
			value: "east",
			is_selected: false,
		},
		{
			id: "west",
			title: "West",
			value: "west",
			is_selected: false,
		},
		{
			id: "north_east",
			title: "North east",
			value: "north_east",
			is_selected: false,
		},
		{
			id: "north_west",
			title: "North west",
			value: "north_west",
			is_selected: false,
		},
		{
			id: "south_east",
			title: "South east",
			value: "south_east",
			is_selected: false,
		},
		{
			id: "south_west",
			title: "South west",
			value: "south_west",
			is_selected: false,
		},
	] as Array<SearchFilterOptionTextActive>,
	selected_options: [] as Array<SearchFilterOptionText>,
	db_collection: "properties",
	db_field: "land_facing",
	is_visible: false,
	is_applied: false,
	is_accordion_open: false,
};
