import StateInterface from "../state-interface";

export const user_profile_loading: StateInterface["user_profile_loading"] = {
  userProfileImages: true,
  userProfileFollowers: true,
  userProfileAboutDetails: true,
  userProfileProfilePostCard: true,
  userProfileOfbPropertyListings: true,
  userProfileSavedProperty: true,
  userProfileSavedService: true,
  userProfileSavedPost: true,
  userProfileSavedOfb: true,
  userProfileReviews: true,
  userProfileAnalyticsPosts: true,
  userProfileAnalyticsOfbProperty: true,
  userProfileLeads: true,
};
