/*

? First, let's import the interface that will describe the shape of our post object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the post object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const post: StateInterface["post"] = {
  _id: "",
  creator_id: {
    user_id: "",
    business_profile_id: "",
    is_business_profile: true,
  },
  body: "This is a post",
  media: [],
  booms: [],
  blasts: [],
  views: [],
  shares: [],
  time_spent: [],
  search_points: 1,
  privacy_status: "public",
  flags: [],
  search_metrics: {
    search_string: "interiors for vaishnavi terraces",
    search_string_delimiter: " ",
    search_points: 1,
    location_coordinates: [0, 0],
  },
  saved_content: [],
  is_blasted: false,
  blasted_post: "",
  deleted: false,
  deleted_at: "",
  created_at: "",
  updated_at: "",
  comments: [],
};
