/*

? First, let's import the interface that will describe the shape of our search_query object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the bidding_cycle object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const search_query: StateInterface["search_query"] = {
    searchType: "Properties",
    location: "HSR Layout",
    coords: "12.9117856,77.6407384",
    sessionId: "",
    pageNumber: "1",
    pageSize: "6",
};