/* State Imports */

import StateInterface from "../state-interface";

/* Is Loading Default State Data */

export const service_loading: StateInterface["service_loading"] = {
 singleServiceImages: true,
  serviceDetails: true,
  singleServiceOverview: true,
  aboutSingleService: true,
  singleServiceCreator: true,
  singleServiceSpecification: true,
  singleServiceProvider: true,
  singleServiceAreaofOperation: true,
  singleServiceLocationCoordinates: true,
  singleServiceReviews: true,
  singleServiceHotServices: true,
  singleServiceTrendingServices: true,
};
