
import StateInterface from "../state-interface";

export const  service_snackbar: StateInterface["service_snackbar"] = { 
    service_details: false,
    service_overview: false,
    service_specification: false,
    service_creator: false,
    about_service_provider: false,
    areas_of_operation: false,
    service_map: false,
    service_images_upload: false,
    service_images_edit: false,
    service_images_delete: false,
}