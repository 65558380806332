import { SearchFilter, SearchFiltersState } from "../../../../redux-magic/sub-interfaces/search-types";
import { getAllInitialSearchFilters, getAllInitialSearchFiltersTest } from "./getAllInitialSearchFilters";

export const generateInitialSearchFiltersReduxState: () => SearchFiltersState = () => {
	return getAllInitialSearchFilters().reduce((accumulator: any, searchFilter: SearchFilter) => {
		accumulator[searchFilter.id] = searchFilter;
		return accumulator;
	}, {});
};

export const generateInitialSearchFiltersReduxStateTest: () => SearchFiltersState = () => {
	return getAllInitialSearchFiltersTest().reduce((accumulator: any, searchFilter: SearchFilter) => {
		accumulator[searchFilter.id] = searchFilter;
		return accumulator;
	}, {});
};
