/*

? First, let's import the interface that will describe the shape of our object.

*/

import StateInterface from "../state-interface";
import { faq } from "./faq";

/*

& Now let's describe an initial state for the object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

const faqs_page_data: StateInterface["faqs_page"] = {
  faqs: [faq],
  search_results: [faq],
};

export const faqs_page = faqs_page_data;

export default faqs_page_data;
