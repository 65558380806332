/*

? First, let's import the interface that will describe the shape of our boost object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the boost object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

const location_data: StateInterface["location"] = {
  location: [12.911964695343453, 77.64083062346324],
};

export const location = location_data;

export default location_data;
