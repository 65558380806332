/*

? First, let's import the interface that will describe the shape of our boost object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the boost object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/
export const location_tracker: StateInterface["location_tracker"] = {
	_id: "",
	location_tracker: {
		_id_copy: "",
		entity_id: "",
		entity_type: "",
		entity_median: 0,
		median_price: 0,
		location_coordinates: [0, 0],
	},
	created_at: "",
};
