/*

? First, let's import the interface that will describe the shape of our bidding_cycle object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the bidding_cycle object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const redux_session: StateInterface["redux_session"] = null;
