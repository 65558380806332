import StateInterface from "../state-interface";

export const property_snackbar: StateInterface["property_snackbar"] = {
property_details: false,
    property_config: false,
    about_property: false,
    property_creator: false,
    property_about_location: false,
    property_amenities: false,
    property_developer: false,
    property_images_upload: false,
    property_images_edit: false,
    property_images_delete: false,
    property_location_coordinates: false,
    property_overview: false,
    property_reviews: false,
    property_vicinity: false,
    neighbourhood_reviews: false,
}