/*

? First, let's import the interface that will describe the shape of our beegru_points transaction object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the beegru_points transaction object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const beegru_points_transaction: StateInterface["beegru_points_transaction"] = {
	_id: "62a0a8e45503a8e31dcdab1a",
	transaction: {
		_id_copy: "62a0a8e45503a8e31dcdab1a",
		created_by: {
			is_business_profile: false,
			user_id: "62a0a8e45503a8e31dcdab1a",
			business_profile_id: "62c82522fc94685e78844935",
		},
		transaction_status: "pending",
		transaction_amount_in_beegru_points: 0,
		transaction_type: "credit",
		transaction_purpose: "boost",
		storage_amount: 0,
		transaction_related_entity: {
			entity_type: "property",
			entity_id: "62b2ed6299a2279a2dd33961",
		},
	},
	created_at: "2022-06-30T18:30:00.000Z",
};
