/*

? First, let's import the interface that will describe the shape of our subscriptions object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the subscriptions object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const subscriptions: StateInterface["subscriptions"] = {
  _id: "62e4dcc7893a5fc8c8b287c4",
  plan_slug: "landowner-premium",
  subscribed_user_id: "62e4dcc7893a5fc8c8b287c4",
  subscribed_entity_id: "62e4dcc7893a5fc8c8b287c4",
  start_date: "2020-01-01T00:00:00.000Z",
  end_date: "2020-01-01T00:00:00.000Z",
  trigger_end_date: "2020-01-01T00:00:00.000Z",
  active: false,
  cancelled: false,
  cancelled_at: "2020-01-01T00:00:00.000Z",
  created_at: "2020-01-01T00:00:00.000Z",
  updated_at: "2020-01-01T00:00:00.000Z",
};
