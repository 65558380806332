/*

? First, let's import the interface that will describe the shape of our services object.

*/

import StateInterface from "../state-interface";

const verify_email_data: StateInterface["verify_email"] = {
    _id: "62e4dcc7893a5fc8c8b287c4",
    recovery_email: "",
    status: "pending",
    user_id: "",
    email_sent_at: new Date(),
    verified_at:  new Date(),
    created_at:  new Date(),
};

export const verify_email = verify_email_data;
export default verify_email_data;
