import { SearchFilter, SearchFilterOptionRange } from "../../../redux-magic/sub-interfaces/search-types";

export const _p_available_from: SearchFilter = {
	id: "available_from",
	title: "Available From",
	level: 4,
	index: 15,
	presentation_type: "date_range_picker",
	multi: false,
	affects: [],
	affected_by: ["property_status"],
	priority: 4,
	is_advanced: false,
	included_in_search_bar: false,
	option_type: "range",
	possible_options: [
		{
			id: "available_from",
			title: "Available From",
			value: {
				min: 0,
				max: 0,
				unlock_max: true,
			},
		},
	] as Array<SearchFilterOptionRange>,
	active_options: [
		{
			id: "available_from",
			title: "Available From",
			value: {
				min: 0,
				max: 0,
				unlock_max: true,
			},
		},
	] as Array<SearchFilterOptionRange>,
	selected_options: [] as Array<SearchFilterOptionRange>,
	db_collection: "properties",
	db_field: "possession_date",
	is_visible: false,
	is_applied: false,
	is_accordion_open: false,
};
