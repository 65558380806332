/*

? First, let's import the interface that will describe the shape of our chat object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the chat object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const chat: StateInterface["chat"] = {
  _id: "",
  members: [],
  admins: [],
  chat_type: "",
  chat_title: "",
  chat_description: "",
  chat_dp: {
    file_id: "",
    original_file_server_path: "",
  },
  introduction_info: {
    introducer: {
      is_business_profile: false,
      user_id: "",
      business_profile_id: "",
    },
    seller: {
      is_business_profile: false,
      user_id: "",
      business_profile_id: "",
      invite_status: "",
      invite_accepted__or_rejected_at: "",
    },
    buyer: {
      is_business_profile: false,
      user_id: "",
      business_profile_id: "",
      invite_status: "",
      invite_accepted__or_rejected_at: "",
    },
    introduced_entity_type: "",
    introduced_entity_id: "",
  },
  created_by: {
    is_business_profile: false,
    user_id: "",
    business_profile_id: "",
  },
  created_at: "",
  is_vanish_mode: false,
  vanish_at: "",
  trigger_vanish_at: "",
  updated_at: "",
  deleted_for: [],
};
