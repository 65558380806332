/*

? First, let's import the interface that will describe the shape of our boost object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the boost object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const user_stack_response: StateInterface["user_stack_response"] = {
    ua: "",
    type: "",
    brand: "",
    name: "",
    url: "",
    os: {
        name: "",
        code: "",
        url: "",
        family: "",
        family_code: "",
        family_vendor: "",
        icon: "",
        icon_large: "",
    },
    device: {
        is_mobile_device: false,
        type: "",
        brand: "",
        brand_code: "",
        brand_url: "",
        name: "",
    },
    browser: {
        name: "",
        version: "",
        version_major: "",
        engine: "",
    },
    crawler: {
        is_crawler: false,
        category: "",
        last_seen: "",
    },
};