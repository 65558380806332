/*

? First, let's import the interface that will describe the shape of our chat message object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the chat message object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const chat_message: StateInterface["chat_message"] = {
  _id: "6310a47582b774b3ac71c5ad",
  chat_id: "6310a47582b774b3ac71c5ad",
  body: "",
  attachments: [],
  system_message: false,
  sender: {
    is_business_profile: false,
    user_id: "6310a47582b774b3ac71c5ad",
    business_profile_id: "6310a47582b774b3ac71c5ad",
  },
  receivers: [],
  delivered_status: [],
  read_status: [],
  is_edited: false,
  edited_at: "",
  is_vanish_mode: false,
  vanish_at: "",
  trigger_vanish_at: "",
  created_at: "",
  updated_at: "",
  deleted_for: [],
};
