import { SearchFilter, SearchFilterOptionLocation } from "../../../redux-magic/sub-interfaces/search-types";

export const _b_location: SearchFilter = {
	id: "location",
	title: "Location",
	level: 0,
	index: 1,
	presentation_type: "autocomplete_text_field",
	multi: false,
	affects: [],
	affected_by: [],
	priority: 0,
	is_advanced: false,
	included_in_search_bar: true,
	option_type: "location",
	possible_options: [
		{
			id: "location",
			title: "Location",
			value: {
				title: "HSR Layout",
				coordinates: [77.6407576, 12.9117067],
			},
		},
	] as Array<SearchFilterOptionLocation>,
	active_options: [
		{
			id: "location",
			title: "Location",
			value: {
				title: "HSR Layout",
				coordinates: [77.6407576, 12.9117067],
			},
		},
	] as Array<SearchFilterOptionLocation>,
	selected_options: [] as Array<SearchFilterOptionLocation>,
	db_collection: null,
	db_field: "search_metrics.location_coordinates",
	is_visible: true,
	is_applied: false,
	is_accordion_open: false,
};
