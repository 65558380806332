import {
	SearchFilter,
	SearchFilterOptionText,
	SearchFilterOptionTextActive,
} from "../../../redux-magic/sub-interfaces/search-types";

export const _v_service_category: SearchFilter = {
	id: "service_category",
	title: "Service Category",
	level: 1,
	index: 21,
	presentation_type: "dropdown",
	multi: false,
	affects: ["service_type"],
	affected_by: ["search_type"],
	priority: 1,
	is_advanced: false,
	included_in_search_bar: false,
	option_type: "text",
	possible_options: [
		{
			id: "regular_service",
			title: "Regular Service",
			value: "regular_service",
		},
		{
			id: "ofb_service_private",
			title: "OFB Service (Private)",
			value: "ofb_service_private",
		},
		{
			id: "ofb_service_public",
			title: "OFB Service (Public)",
			value: "ofb_service_public",
		},
	] as Array<SearchFilterOptionText>,
	active_options: [
		{
			id: "regular_service",
			title: "Regular Service",
			value: "regular_service",
			is_selected: true,
		},
		{
			id: "ofb_service_private",
			title: "OFB Service (Private)",
			value: "ofb_service_private",
			is_selected: false,
		},
		{
			id: "ofb_service_public",
			title: "OFB Service (Public)",
			value: "ofb_service_public",
			is_selected: false,
		},
	] as Array<SearchFilterOptionTextActive>,
	selected_options: [
		{
			id: "regular_service",
			title: "Regular Service",
			value: "regular_service",
		},
	] as Array<SearchFilterOptionText>,
	db_collection: "services",
	db_field: "service_category",
	is_visible: false,
	is_applied: false,
	is_accordion_open: false,
};
