import StateInterface from "../state-interface";
export const home_loading: StateInterface["home_loading"] = {
  loading_featured_properties: true,
  loading_hot_properties: true,
  loading_top_services: true,
  // loading_trending_services: true,
  loading_top_users: true,
  // loading_trending_users: true,
  loading_top_business_profiles: true,
  // loading_trending_business_profiles: true,
};
