import {
	SearchFilter,
	SearchFilterOptionText,
	SearchFilterOptionTextActive,
} from "../../../redux-magic/sub-interfaces/search-types";

export const _d_property_category: SearchFilter = {
	id: "property_category",
	title: "Property Category",
	level: 1,
	index: 3,
	presentation_type: "dropdown",
	multi: false,
	affects: ["property_type"],
	affected_by: ["search_type"],
	priority: 1,
	is_advanced: false,
	included_in_search_bar: true,
	option_type: "text",
	possible_options: [
		{
			id: "residential",
			title: "Residential",
			value: "residential",
		},
		{
			id: "commercial",
			title: "Commercial",
			value: "commercial",
		},
		{
			id: "agricultural",
			title: "Agricultural",
			value: "agricultural",
		},
		{
			id: "warehousing",
			title: "Warehousing",
			value: "warehousing",
		},
		{
			id: "industrial",
			title: "Industrial",
			value: "industrial",
		},
	] as Array<SearchFilterOptionText>,
	active_options: [
		{
			id: "residential",
			title: "Residential",
			value: "residential",
			is_selected: true,
		},
		{
			id: "commercial",
			title: "Commercial",
			value: "commercial",
			is_selected: false,
		},
		{
			id: "agricultural",
			title: "Agricultural",
			value: "agricultural",
			is_selected: false,
		},
		{
			id: "warehousing",
			title: "Warehousing",
			value: "warehousing",
			is_selected: false,
		},
		{
			id: "industrial",
			title: "Industrial",
			value: "industrial",
			is_selected: false,
		},
	] as Array<SearchFilterOptionTextActive>,
	selected_options: [
		{
			id: "residential",
			title: "Residential",
			value: "residential",
		},
	] as Array<SearchFilterOptionText>,
	db_collection: "properties",
	db_field: "property_category",
	is_visible: true,
	is_applied: true,
	is_accordion_open: true,
};
