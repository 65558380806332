/* State Interface Imports */

import StateInterface from "../state-interface";

/* Default State Imports */

import { business_profile } from "./sub-state-data";

/* Single Business Profile Page Default State Data */

export const single_business_profile: StateInterface["single_business_profile"] =
  {
    business_profile: business_profile,
    properties: [],
    propCount: 0,
    servicesCount: 0,
    ofbpropertiesCount: 0,
    savedPropertiesLength: 0,
    savedServicesLength: 0,
  savedPostsLength: 0,
    savedOFBLength: 0,
    searched_property: [],
    properties_og: [],
    ofb_properties: [],
    ofb_properties_og: [],
    posts: [],
    postsCount: 0,
    reviews: [],
    reviewsCount: 0,
    given_reviews: [],
    given_reviewsCount: 0,
    boosts: [],
    services: [],
    services_og: [],
    leads: [],
    saved_items: {
      properties: [],
      services: [],
      posts: [],
      ofb: [],
    },
    analytics: {
      post_booms_per_day: [],
      post_blasts_per_day: [],
      post_views_per_day: [],
      property_views_per_day: [],
      ofb_property_views_per_day: [],
      service_views_per_day: [],
    },
    boosted_entity_data: [],
    published_properties: [],
    published_services: [],
  };
