/*

? First, let's import the interface that will describe the shape of our bidding_cycle object.

*/

import { ObjectId } from "bson";
import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the dlt_sms_record object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

const dlt_sms_record_internal: StateInterface["dlt_sms_record"] =
{
  _id: new ObjectId().toString(),
  dlt_template_id: new ObjectId().toString(),
  dlt_template_friendly_identifier: "otp",
  message_id: "65f417e0e25468509881c9ad",
  message_body: "Your Beegru verification code is: 969420. Don't share this code with anyone; our employees will never ask for the code.",
  message_body_variable_index_map: [
    {
      _id: new ObjectId().toString(),
      index: 1,
      variable_name: "otp",
      variable_content: "969420",
      variable_start_index: 30,
      variable_end_index: 35,
      variable_length: 6,
    },
  ],
  recipient: {
    user_id: new ObjectId().toString(),
    country_code: "+91",
    phone: "9900608821",
  },
  status: {
    response_code: 200,
    response_message: "Message sent successfully",
    error: false,
  },
  created_at: "2022-06-30T18:30:00.000Z",
  updated_at: "2022-06-30T18:30:00.000Z",
  };

export const dlt_sms_record =
  dlt_sms_record_internal;

export default dlt_sms_record;