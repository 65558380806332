import StateInterface from "../state-interface";

export const on_boarding_details: StateInterface["on_boarding_details"] = {
  index: 1,
  checked: false,
  slug: "",
  profile_type: "",
  business_profile_id: "",
  action: "business",
  organization_title: "",
  first_name: "",
  last_name: "",
  email: "",
  direction: "",
  progress: 0,
};
