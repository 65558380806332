import {
	SearchFilter,
	SearchFilterOptionText,
	SearchFilterOptionTextActive,
} from "../../../redux-magic/sub-interfaces/search-types";

export const _m_plot_dimensions: SearchFilter = {
	id: "plot_dimensions",
	title: "Plot Dimensions",
	level: 3,
	index: 12,
	presentation_type: "dropdown",
	multi: false,
	affects: [],
	affected_by: ["property_type"],
	priority: 3,
	is_advanced: false,
	included_in_search_bar: false,
	option_type: "text",
	possible_options: [
		{
			id: "plot_dimensions_20_x_30",
			title: "20x30",
			value: "20_x_30",
		},
		{
			id: "plot_dimensions_30_x_40",
			title: "30x40",
			value: "30_x_40",
		},
		{
			id: "plot_dimensions_30_x_50",
			title: "30x50",
			value: "30_x_50",
		},
		{
			id: "plot_dimensions_40_x_60",
			title: "40x60",
			value: "40_x_60",
		},
		{
			id: "plot_dimensions_50_x_80",
			title: "50x80",
			value: "50_x_80",
		},
		{
			id: "plot_dimensions_60_x_90",
			title: "60x90",
			value: "60_x_90",
		},
		{
			id: "plot_dimensions_80_x_120",
			title: "80x120",
			value: "80_x_120",
		},
		{
			id: "plot_dimensions_other",
			title: "Other",
			value: "other",
		},
	] as Array<SearchFilterOptionText>,
	active_options: [
		{
			id: "plot_dimensions_20_x_30",
			title: "20x30",
			value: "20_x_30",
			is_selected: true,
		},
		{
			id: "plot_dimensions_30_x_40",
			title: "30x40",
			value: "30_x_40",
			is_selected: false,
		},
		{
			id: "plot_dimensions_30_x_50",
			title: "30x50",
			value: "30_x_50",
			is_selected: false,
		},
		{
			id: "plot_dimensions_40_x_60",
			title: "40x60",
			value: "40_x_60",
			is_selected: false,
		},
		{
			id: "plot_dimensions_50_x_80",
			title: "50x80",
			value: "50_x_80",
			is_selected: false,
		},
		{
			id: "plot_dimensions_60_x_90",
			title: "60x90",
			value: "60_x_90",
			is_selected: false,
		},
		{
			id: "plot_dimensions_80_x_120",
			title: "80x120",
			value: "80_x_120",
			is_selected: false,
		},
		{
			id: "plot_dimensions_other",
			title: "Other",
			value: "other",
			is_selected: false,
		},
	] as Array<SearchFilterOptionTextActive>,
	selected_options: [
		// {
		// 	id: "plot_dimensions_20_x_30",
		// 	title: "20x30",
		// 	value: "20_x_30",
		// },
	] as Array<SearchFilterOptionText>,
	db_collection: "properties",
	db_field: "plot_dimensions",
	is_visible: false,
	is_applied: false,
	is_accordion_open: false,
};
