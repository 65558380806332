/*

? First, let's import the interface that will describe the shape of our property object.

*/

import StateInterface from "../state-interface";
import { property } from "./property";

/*

& Now let's describe an initial state for the property object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

const single_property_page_data: StateInterface["single_property_page"] = {
	property: property,
	trendingProperties: [property],
	hotProperties: [property],
	propertyReviews: [],
	neighbourhoodReviews: [],
	nearbyProperties: [],
};

export const single_property_page = single_property_page_data;

export default single_property_page_data;
