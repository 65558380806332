/*

? First, let's import the interface that will describe the shape of our bidding_cycle object.

*/

import { ObjectId } from "bson";
import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the boost_pricing_manual_override object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

const boost_pricing_manual_override_internal: StateInterface["boost_pricing_manual_override"] =
  {
    _id: new ObjectId().toString(),
    location: {
      title: "",
      coordinates: [0, 0],
    },
    applicable_radius: 10000,
    base_price: 1,
    static_surge_multiplier: 1,
    use_static_surge_multiplier: false,
    activated: false,
    activation_strategy: "permanent",
    days_of_week: {
      monday: {
        in_use: false,
        activates_at: "2100-01-01T00:00:00.000Z",
        deactivates_at: "2100-01-01T00:00:00.000Z",
      },
      tuesday: {
        in_use: false,
        activates_at: "2100-01-01T00:00:00.000Z",
        deactivates_at: "2100-01-01T00:00:00.000Z",
      },
      wednesday: {
        in_use: false,
        activates_at: "2100-01-01T00:00:00.000Z",
        deactivates_at: "2100-01-01T00:00:00.000Z",
      },
      thursday: {
        in_use: false,
        activates_at: "2100-01-01T00:00:00.000Z",
        deactivates_at: "2100-01-01T00:00:00.000Z",
      },
      friday: {
        in_use: false,
        activates_at: "2100-01-01T00:00:00.000Z",
        deactivates_at: "2100-01-01T00:00:00.000Z",
      },
      saturday: {
        in_use: false,
        activates_at: "2100-01-01T00:00:00.000Z",
        deactivates_at: "2100-01-01T00:00:00.000Z",
      },
      sunday: {
        in_use: false,
        activates_at: "2100-01-01T00:00:00.000Z",
        deactivates_at: "2100-01-01T00:00:00.000Z",
      },
    },
    activates_at: "2100-01-01T00:00:00.000Z",
    deactivates_at: "2100-01-01T00:00:00.000Z",
    rationale: "",
    created_by: new ObjectId().toString(),
    created_at: "2022-06-30T18:30:00.000Z",
    updated_at: "2022-06-30T18:30:00.000Z",
  };

export const boost_pricing_manual_override =
  boost_pricing_manual_override_internal;

export default boost_pricing_manual_override;
