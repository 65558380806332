import {
	SearchFilter,
	SearchFilterOptionText,
	SearchFilterOptionTextActive,
} from "../../../redux-magic/sub-interfaces/search-types";

export const _a_search_type: SearchFilter = {
	id: "search_type",
	title: "I'm looking for",
	level: 0,
	index: 0,
	presentation_type: "tabs",
	multi: false,
	affects: ["transaction_type", "property_category", "budget", "service_category", "listed_by"],
	affected_by: [],
	priority: 0,
	is_advanced: false,
	included_in_search_bar: true,
	option_type: "text",
	possible_options: [
		{
			id: "properties",
			title: "Properties",
			value: "properties",
		},
		{
			id: "services",
			title: "Services",
			value: "services",
		},
		{
			id: "agents",
			title: "Agents",
			value: "agents",
		},
		{
			id: "developers",
			title: "Developers",
			value: "developers",
		},
		{
			id: "landowners",
			title: "Landowners",
			value: "landowners",
		},
		{
			id: "professionals",
			title: "Professionals",
			value: "professionals",
		},
		{
			id: "users",
			title: "Users",
			value: "users",
		},
	] as Array<SearchFilterOptionText>,
	active_options: [
		{
			id: "properties",
			title: "Properties",
			value: "properties",
			is_selected: true,
		},
		{
			id: "services",
			title: "Services",
			value: "services",
			is_selected: false,
		},
		{
			id: "agents",
			title: "Agents",
			value: "agents",
			is_selected: false,
		},
		{
			id: "developers",
			title: "Developers",
			value: "developers",
			is_selected: false,
		},
		{
			id: "landowners",
			title: "Landowners",
			value: "landowners",
			is_selected: false,
		},
		{
			id: "professionals",
			title: "Professionals",
			value: "professionals",
			is_selected: false,
		},
		{
			id: "users",
			title: "Users",
			value: "users",
			is_selected: false,
		},
	] as Array<SearchFilterOptionTextActive>,
	selected_options: [
		{
			id: "properties",
			title: "Properties",
			value: "properties",
		},
	] as Array<SearchFilterOptionText>,
	db_collection: null,
	db_field: null,
	is_visible: true,
	is_applied: true,
	is_accordion_open: false,
};
