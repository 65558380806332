
import StateInterface from "../state-interface";

export const user_profile_snackbar: StateInterface["user_profile_snackbar"] = {
  upload_cover_image: false,
  edit_cover_image: false,
  delete_cover_image: false,
  upload_profile_picture: false,
  edit_profile_picture: false,
  delete_profile_picture: false,
  update_about: false,
  delete_ofb_listing: false,
  corrupt_file: false,
};