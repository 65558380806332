/* State Imports */

import StateInterface from "../state-interface";
import { post } from "../sub-state-data-empty/post";

/* Property List Default State Data */

const saved_posts_list_data: StateInterface["saved_posts_list"] = [post];

export const saved_posts_list = saved_posts_list_data;
export default saved_posts_list_data;