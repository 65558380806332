/* State Imports */

import StateInterface from "../state-interface";
import { property } from "./sub-state-data-empty";

/* Property Reviews Page Default State Data */

export const property_reviews_page: StateInterface["property_reviews_page"] = {
  property_data: property,
  property_reviews: [],
};
