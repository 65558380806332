/*

? First, let's import the interface that will describe the shape of our bidding_cycle object.

*/

import { ObjectId } from "bson";
import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the dlt_template object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

const otp_internal: StateInterface["otp"] =
{
    _id: new ObjectId().toString(),
    dlt_sms_record_id: new ObjectId().toString(),
    recipient: {
        user_id: new ObjectId().toString(),
        country_code: "+91",
        phone: "1234567890"
    },
    otp: "123456",
    verified: {
        _id: new ObjectId().toString(),
        verified: false,
    },
    is_retry: false,
    retry_attempt_number: 0,
    created_at: new Date().toString(),
    updated_at: new Date().toString(),
};

export const otp =
  otp_internal;

export default otp;