import { SearchFilter, SearchFilterOptionTextActive } from "../../../../redux-magic/sub-interfaces/search-types";

import {
	amenities,
	area,
	available_from,
	balconies,
	bathrooms,
	bedrooms,
	budget,
	floors,
	furnishing,
	land_facing,
	listed_by,
	location,
	plot_dimensions,
	possession_date,
	property_category,
	property_status,
	property_type,
	search_type,
	seats,
	security_deposit,
	service_category,
	service_subtype,
	service_type,
	transaction_type,
	unit_facing,
} from "../index";

export const SearchFiltersArray: Array<SearchFilter> = [
	search_type,
	location,
	transaction_type,
	property_category,
	property_type,
	budget,
	security_deposit,
	bedrooms,
	area,
	bathrooms,
	balconies,
	seats,
	plot_dimensions,
	property_status,
	possession_date,
	available_from,
	unit_facing,
	land_facing,
	floors,
	furnishing,
	amenities,
	service_category,
	service_type,
	service_subtype,
	listed_by,
];

export const SearchFiltersArrayTest: Array<SearchFilter> = [
	{
		...search_type,
		is_applied: true,
	},
	{
		...location,
		is_applied: true,
		selected_options: [
			{
				id: "location",
				title: "Location",
				value: {
					title: "HSR Layout",
					coordinates: [77.6407576, 12.9117067],
				},
			},
		],
	},
	{ ...transaction_type, is_applied: false },
	{ ...property_category, is_applied: false },
	{
		...property_type,
		is_applied: false,
		selected_options: [
			{
				id: "apartment",
				title: "Apartment",
				value: "Apartment",
			},
		],
		active_options: property_type.active_options.map((option: SearchFilterOptionTextActive) => {
			if (option.id === "apartment") {
				return {
					...option,
					value: "Apartment",
					is_selected: true,
				};
			}
			return {
				...option,
				is_selected: false,
			};
		}),
	},
	{
		...budget,
		is_applied: false,
		selected_options: [
			{
				id: "budget",
				title: "Budget",
				value: {
					min: 0,
					max: 123132131313,
					unlock_max: true,
				},
			},
		],
	},
	security_deposit,
	bedrooms,
	{
		...area,
		is_applied: false,
		selected_options: [
			{
				id: "area",
				title: "Area",
				value: {
					min: 1462,
					max: 2243,
					unlock_max: false,
				},
			},
		],
	},
	bathrooms,
	balconies,
	seats,
	plot_dimensions,
	property_status,
	possession_date,
	available_from,
	unit_facing,
	land_facing,
	{ ...floors, is_applied: false },
	furnishing,
	amenities,
	{ ...service_category, is_applied: false },
	service_type,
	service_subtype,
	listed_by,
];
