import { SearchFilter } from "../../../../redux-magic/sub-interfaces/search-types";
import { SearchFiltersArray, SearchFiltersArrayTest } from "../collations";

export const getAllInitialSearchFilters: () => Array<SearchFilter> = () => {
	return SearchFiltersArray as Array<SearchFilter>;
};

export const getAllInitialSearchFiltersTest: () => Array<SearchFilter> = () => {
	return SearchFiltersArrayTest as Array<SearchFilter>;
};
