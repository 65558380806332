/*

? First, let's import the interface that will describe the shape of our chat states object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the chat states object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const chat_states: StateInterface["chat_states"] = {
  drawer_screen: "",
  current_chat: {
    chat_id: "",
    chat_type: "",
    chat_title: "",
    chat_description: "",
    chat_members: [],
    chat_admins: [],
  },
};
