/* State Imports */

import StateInterface from "../state-interface";
import { service } from "./service";

/* Single Service Page Default Data */

const single_service_page_data: StateInterface["single_service_page"] = {
  service_data: service,
  service_reviews: [],
  trendingServices: [],
  hotServices: [],
  user_profile: {},
  business_profile: {},
};

export const single_service_page = single_service_page_data;

export default single_service_page_data;
