/* State Imports */

import StateInterface from "../state-interface";

/* Global Search Results Default State Data */

export const global_search_results: StateInterface["global_search_results"] = {
  results: [],
  number_of_results: 1,
  search_duration: 0,
};