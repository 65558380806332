/*

? First, let's import the interface that will describe the shape of our cash transaction object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the cash transaction object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const cash_transaction: StateInterface["cash_transaction"] = {
	_id: "62a0a8e45503a8e31dcdab1a",
	transaction: {
		_id_copy: "62a0a8e45503a8e31dcdab1a",
		user_id: "62a0a8e45503a8e31dcdab1a",
		pack_of_1: {
			pack_price: 0,
			quantity: 0,
			total_price: 0,
			total_beegru_points: 0,
		},
		pack_of_10: {
			pack_price: 0,
			quantity: 0,
			total_price: 0,
			total_beegru_points: 0,
		},
		pack_of_100: {
			pack_price: 0,
			quantity: 0,
			total_price: 0,
			total_beegru_points: 0,
		},
		pack_of_1000: {
			pack_price: 0,
			quantity: 0,
			total_price: 0,
			total_beegru_points: 0,
		},
		beegru_points_amount: 0,
		sub_total: 0,
		gst: 0,
		fiat_amount: 0,
		conversion_rate: 60,
		currency: "INR",
		order_id: "62a0a8e45503a8e31dcdab1a",
		order_status: "pending",
		order_amount: 0,
		order_amount_paid: 0,
		order_amount_due: 0,
		order_currency: "INR",
		order_payment_attempts: 0,
		order_receipt: "62a0a8e45503a8e31dcdab1a",
		payment: {
			razorpay_payment_id: "62a0a8e45503a8e31dcdab1a",
			razorpay_order_id: "62a0a8e45503a8e31dcdab1a",
			razorpay_signature: "62a0a8e45503a8e31dcdab1a",
			reason_for_decline: "62a0a8e45503a8e31dcdab1a",
			created_at: "2022-06-30T18:30:00.000Z",
		},
		cookie: "",
		transaction_timeout_at: "2022-06-30T18:30:00.000Z",
	},
	created_at: "2022-06-30T18:30:00.000Z",
};
