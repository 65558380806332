import {
	SearchFilter,
	SearchFilterOptionText,
	SearchFilterOptionTextActive,
} from "../../../redux-magic/sub-interfaces/search-types";

export const _y_listed_by: SearchFilter = {
	id: "listed_by",
	title: "Listed By",
	level: 1,
	index: 24,
	presentation_type: "chips",
	multi: true,
	affects: [],
	affected_by: ["search_type"],
	priority: 1,
	is_advanced: true,
	included_in_search_bar: true,
	option_type: "text",
	possible_options: [
		{
			id: "agent",
			title: "Agent",
			value: "agent",
		},
		{
			id: "developer",
			title: "Developer",
			value: "developer",
		},
		{
			id: "landowner",
			title: "Landowner",
			value: "landowner",
		},
		{
			id: "professional",
			title: "Professional",
			value: "professional",
		},
		{
			id: "user",
			title: "User",
			value: "user",
		},
	] as Array<SearchFilterOptionText>,
	active_options: [
		{
			id: "agent",
			title: "Agent",
			value: "agent",
			is_selected: false,
		},
		{
			id: "developer",
			title: "Developer",
			value: "developer",
			is_selected: false,
		},
		{
			id: "landowner",
			title: "Landowner",
			value: "landowner",
			is_selected: false,
		},
		{
			id: "professional",
			title: "Professional",
			value: "professional",
			is_selected: false,
		},
	] as Array<SearchFilterOptionTextActive>,
	selected_options: [] as Array<SearchFilterOptionText>,
	db_collection: null,
	db_field: "listedBy",
	is_visible: true,
	is_applied: false,
	is_accordion_open: false,
};
