import { SearchFilter, SearchFilterOptionRange } from "../../../redux-magic/sub-interfaces/search-types";

export const _f_budget: SearchFilter = {
	id: "budget",
	title: "Budget",
	level: 1,
	index: 5,
	presentation_type: "range_text_fields",
	multi: false,
	affects: [],
	affected_by: ["search_type"],
	priority: 1,
	is_advanced: false,
	included_in_search_bar: true,
	option_type: "range",
	possible_options: [
		{
			id: "budget",
			title: "Budget",
			value: {
				min: 0,
				max: 0,
				unlock_max: true,
			},
		},
	] as Array<SearchFilterOptionRange>,
	active_options: [
		{
			id: "budget",
			title: "Budget",
			value: {
				min: 0,
				max: 0,
				unlock_max: true,
			},
		},
	] as Array<SearchFilterOptionRange>,
	selected_options: [] as Array<SearchFilterOptionRange>,
	db_collection: null,
	db_field: "price",
	is_visible: true,
	is_applied: false,
	is_accordion_open: true,
};
