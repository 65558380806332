/*

? First, let's import the interface that will describe the shape of our bidding_cycle object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the bidding_cycle object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const session: StateInterface["session"] = {
	_id: "",
	user_id: "",
	auth_log_id: "",
	connection_id: [],
	current_connection_id: "",
	user_agent: "",
	ip_address: "",
	online: false,
	invalidated: {
		status: false,
		reason: "",
		invalidated_at: "",
	},
	created_at: "",
	updated_at: "",
	expires_at: "",
};
