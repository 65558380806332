/*

? First, let's import the interface that will describe the shape of our bidding_cycle object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the bidding_cycle object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const bidding_cycles: StateInterface["bidding_cycles"] = {
  property_id: "62b2ed6299a2279a2dd33961",
  bid_cycle_start_date: "2022-06-30T18:30:00.000Z",
  bid_cycle_end_date: "2022-07-30T18:30:00.000Z",
  base_bid: 69,
  bids: [
    {
      claimant_id: {
        business_profile_id: "62c82522fc94685e78844935",
        is_business_profile: false,
        user_id: "62097a11b67977b3378b0193",
      },
      bid_amount: 0.72,
      bid_at: "2022-05-25T11:17:01.361Z",
    },
  ],
  created_at: "2022-05-25T11:17:01.361Z",
  updated_at: "2022-05-25T11:17:01.361Z",
};
