/*

? First, let's import the interface that will describe the shape of our post object.

*/

import StateInterface from "../state-interface";
import { post } from "./post";
import { business_profile } from "./business-profile";
import { user } from "./user";

/*

& Now let's describe an initial state for the post object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

const single_post_page_data: StateInterface["single_post_page"] = {
    post: post,
    profileData: business_profile ,
};

export const single_post_page = single_post_page_data;

export default single_post_page_data;