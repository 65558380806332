/*

? First, let's import the interface that will describe the shape of our search_query object.

*/

import { SearchApiResponseInterface } from "../sub-interfaces/search-types";

/*

& Now let's describe an initial state for the search_filter_metrices object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const SeacrhApiResponse: SearchApiResponseInterface = {
	boosted: [],
	boosted_distance: [],
	non_boosted: [],
	non_boosted_distance: [],
	cache_hit: false,
	filter_hash: "",
	search_log_id: "",
	server_execution_time: 0,
	search_duration: 0,
	no_of_boosted_results: 0,
	no_of_non_boosted_results: 0,
	total_no_of_results: 0,
};
